import styled from 'styled-components'

export type NavItem = {
  key: string
  name: string
  icon?: string | React.ReactNode
  itemClass?: string
  iconActive?: React.ReactNode
  iconInactive?: React.ReactNode
}

export interface KNavbarProps {
  selected?: string
  onChangeItem: (key: any) => void
  navBarClass?: string
  items: NavItem[]
}

export interface NavBarItemProps {
  name: string
  active: boolean
  onChangeItem?: () => void
  icon?: string | React.ReactNode
  itemClass?: string
  iconActive?: React.ReactNode
  iconInactive?: React.ReactNode
}

const NavbarContainer = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #3c3c3c;
  border-radius: 0;
  border-bottom: 5px solid #c0c0c0;
  gap: 0 !important;
`

const NavbarItemContainer = styled.li`
  margin: 0 15px;
  position: relative;
`

const NavbarButton = styled.button<{ active: boolean }>`
  background: none;
  border: none;
  color: ${(props) => (props.active ? '#f1b625' : '#fff')};
  font-size: 16px;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  transition: all 0.3s;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  min-height: 92px;
  position: relative;

  &:hover {
    color: #f1b625;
  }

  i {
    margin-bottom: 8px;
  }

  svg {
    margin-bottom: 8px;
    width: 32px;
    height: auto;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    height: 5px;
    background-color: ${(props) => (props.active ? '#f1b625' : '#c0c0c0')};
    transition: background-color 0.3s ease;
  }
`

export const KNavBarItem = ({
  active,
  onChangeItem,
  icon,
  name,
  iconActive,
  iconInactive
}: NavBarItemProps) => (
  <NavbarItemContainer>
    <NavbarButton active={active} onClick={onChangeItem}>
      {active ? iconActive : iconInactive}
      <span>{name}</span>
    </NavbarButton>
  </NavbarItemContainer>
)

const KNavbar = ({
  selected,
  onChangeItem,
  navBarClass,
  items
}: KNavbarProps) => {
  return (
    <NavbarContainer className={navBarClass}>
      {items.map((i) => (
        <KNavBarItem
          name={i.name}
          key={i.key}
          active={i.key === selected}
          itemClass={i.itemClass}
          icon={i.icon}
          iconActive={i.iconActive}
          iconInactive={i.iconInactive}
          onChangeItem={() => onChangeItem(i.key)}
        />
      ))}
    </NavbarContainer>
  )
}

export default KNavbar
