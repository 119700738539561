import React from 'react'
import styled from 'styled-components'

export interface KNumberGridProps {
  numbers: Array<{
    number: string
    active: boolean
  }>
  onClickNumber: (number: string) => void
  getLimitTypeUI: (number: string, menuSelected: string[]) => { limit: string }
  menuSelected: string[]
}

const NumberContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 5px;
  }
`

const NumberItem = styled.li`
  flex-basis: calc(10% - 10px);
  max-width: calc(10% - 10px);
  margin: 5px 0;

  @media (max-width: 768px) {
    flex-basis: calc(16.66% - 5px);
    max-width: calc(16.66% - 5px);
  }
`

const PanelButton = styled.a`
  cursor: pointer;
  &.limit,
  &.limit:hover {
    background-color: #000 !important;
    cursor: not-allowed;
  }
  &.range {
    background-color: #ffc302;
  }
`

const KNumberGrid: React.FC<KNumberGridProps> = ({
  numbers,
  onClickNumber,
  getLimitTypeUI,
  menuSelected
}) => {
  return (
    <NumberContainer>
      {numbers.map((item) => (
        <NumberItem key={item.number}>
          <PanelButton
            className={`text-decoration-none btn-outline-light-grey ${
              item.active && 'active'
            } ${getLimitTypeUI(item.number, menuSelected).limit}`}
            onClick={() => onClickNumber(item.number)}
          >
            {item.number}
          </PanelButton>
        </NumberItem>
      ))}
    </NumberContainer>
  )
}

export default KNumberGrid
