import SwapNumberButton from 'components/@kapook/SwapNumberButton/KSwapNumberButton'
import LottoSearch from 'components/LottoSearch'

import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
type LottoBetTabLayoutProps = {
  swapNumber?: boolean
  onToggleSwapNumber?: () => void
  hideSwap?: boolean
  searchDigit?: string
  onSearchDigit?: (e: ChangeEvent<HTMLInputElement>) => void
}
const LottoBetTabLayout = ({
  swapNumber,
  onToggleSwapNumber,
  onSearchDigit,
  searchDigit,
  hideSwap
}: LottoBetTabLayoutProps) => {
  const { t } = useTranslation()
  return (
    <div className="search-lotto-number-container container mt-4 mb-4">
      <div className="row mb-4">
        <div className={`col-12 ${!hideSwap && 'col-lg-8'} mb-2 mb-lg-0`}>
          <LottoSearch onSearch={onSearchDigit} value={searchDigit} />
        </div>
        {!hideSwap && (
          <div className="col-12 col-lg-4 mb-2 mb-lg-0">
            <SwapNumberButton
              swapNumber={swapNumber}
              onToggleSwapNumber={onToggleSwapNumber}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default LottoBetTabLayout
