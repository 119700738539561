import styled from 'styled-components'

interface BetPageContainerProps {
  topRadius?: boolean
}

const BetPageContainer = styled.div<BetPageContainerProps>`
  background-color: #f8f8f8;
  border-radius: ${(props) =>
    props.topRadius ? '15px 15px 0 0' : '0 0 15px 15px'};
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`

export default BetPageContainer
