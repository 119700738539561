import { useEffect } from 'react'
import { BetItemData } from 'components/ListManualBet'
import { useManualBetItem } from 'components/ListManualBet/useManualBetItem'
import { useNumberInput } from 'components/NumberInput/useNumberInput'
import { useLottoContext } from 'contexts'
import {
  generateNineteenNumber,
  generatePermutations,
  generateSwitchNumber
} from 'utils/number-utils'
import { useTranslation } from 'react-i18next'
import { BetLottoType } from 'contexts/lotto-context'
import { LottoConfigObject } from 'utils/lotto-type-utils'

export const useManualBet = () => {
  const BET_TWO = 'betTwo'
  const BET_THREE = 'betThree'
  const BET_FOUR = 'betFour'
  const BET_SIX = 'betSix'
  const BET_ONE = 'betOne'
  const { lottoConfig, addLottoListAndHandleResponse } = useLottoContext()
  const { t } = useTranslation()
  const FUNCTION_MENU: BetItemData[] = [
    {
      name: t('betDetailPanel.betLottoType.19_number'),
      key: '19_number',
      digit: 1
    },
    {
      name: t('betDetailPanel.betLottoType.double'),
      key: 'double',
      digit: 1
    },
    {
      name: t('betDetailPanel.betLottoType.start_number'),
      key: 'start_number',
      digit: 1
    },
    {
      name: t('betDetailPanel.betLottoType.end_number'),
      key: 'end_number',
      digit: 1
    },
    {
      name: t('betDetailPanel.betLottoType.low_number'),
      key: 'low_number',
      digit: 1
    },
    {
      name: t('betDetailPanel.betLottoType.high_number'),
      key: 'high_number',
      digit: 1
    },
    {
      name: t('betDetailPanel.betLottoType.odd_number'),
      key: 'odd_number',
      digit: 1
    },
    {
      name: t('betDetailPanel.betLottoType.even_number'),
      key: 'even_number',
      digit: 1
    },
    {
      name: t('betDetailPanel.betLottoType.swap_two'),
      key: 'swap_two',
      digit: 2
    },
    {
      name: t('betDetailPanel.betLottoType.swap_three'),
      key: 'swap_three',
      digit: 3
    }
  ]
  const initBetItem = () => {
    const betItemDataArray: BetItemData[] = []
    const getDigit = (type: string) => {
      const digitMap: { [key: string]: number } = {
        betTwo: 2,
        betThree: 3,
        betOne: 1,
        betSix: 6,
        betFour: 4
      }
      return digitMap[type] || NaN
    }
    Object.entries(lottoConfig).forEach(([digitType, digitConfig]) => {
      Object.entries(digitConfig).forEach(([key, value]) => {
        if (!['bet_four', 'bet_six'].includes(key)) {
          const betItem: BetItemData = {
            name: t(`betDetailPanel.betLottoType.${key}`) + `  (${value})`,
            key: key,
            digit: getDigit(digitType)
          }
          betItemDataArray.push(betItem)
        }
      })
    })
    return betItemDataArray
  }
  const initBet = [...initBetItem(), ...FUNCTION_MENU]
  const {
    maxDigit,
    selected,
    onChangeBetItem,
    injectFunctionLottoBet,
    hasTwoDigitSpecial
  } = useManualBetItem([initBet?.[0]?.key])
  const { addDigit, currentNumber, deleteNumber, resetNumber } =
    useNumberInput(maxDigit)
  const onSelectNewMenu = (key: string) => {
    resetNumber()
    if (
      ![
        'double',
        'high_number',
        'low_number',
        'odd_number',
        'even_number'
      ].includes(key)
    ) {
      onChangeBetItem(key)
    }
  }
  const renderItem = injectFunctionLottoBet(initBet, selected)
  const betTwoKeys = Object.keys(lottoConfig.betTwo)
  const betThreeKeys = Object.keys(lottoConfig.betThree)
  type ConfigKey = keyof typeof lottoConfig | string
  const isConfigKeyValid = (
    configKey: ConfigKey
  ): configKey is keyof typeof lottoConfig => {
    return configKey in lottoConfig
  }

  const isMenuOptionValid = (
    configKey: keyof typeof lottoConfig,
    menuOption: BetLottoType
  ): boolean => {
    return Boolean(lottoConfig[configKey][menuOption])
  }

  const addLottoListIfConfigExists = async (
    configKey: ConfigKey,
    digitCount: number,
    menuOption: BetLottoType
  ): Promise<void> => {
    if (
      isConfigKeyValid(configKey) &&
      isMenuOptionValid(configKey, menuOption)
    ) {
      const currentBet: string[] = [...currentNumber.map(String)].slice(
        0,
        digitCount
      )
      const setSelect = new Set([...selected])
      const isSpecialTwoDigit = hasTwoDigitSpecial(setSelect)
      if (!(configKey === BET_TWO && isSpecialTwoDigit)) {
        const typesWithRates = lottoConfig[configKey]
        const params: LottoConfigObject = {}
        params[menuOption] = typesWithRates[menuOption]
        addLottoListAndHandleResponse(currentBet.join(''), params)
      }
    }
  }

  const handleNumbers = (
    numbers: string[],
    selected: string[],
    betKeys: string[]
  ) => {
    if (selected.some((i) => betKeys.includes(i as BetLottoType))) {
      let configKey: keyof typeof lottoConfig
      if (betKeys === betTwoKeys) {
        configKey = 'betTwo'
      } else if (betKeys === betThreeKeys) {
        configKey = 'betThree'
      }
      const params: LottoConfigObject = selected.reduce((acc, i) => {
        const key: BetLottoType = i as BetLottoType
        if (lottoConfig[configKey][key]) {
          acc[key] = lottoConfig[configKey][key]
        }
        return acc
      }, {} as LottoConfigObject)
      for (const number of numbers) {
        addLottoListAndHandleResponse(number, params)
      }
    }
  }

  useEffect(() => {
    if (currentNumber.length === maxDigit) {
      selected.forEach((menuOption) => {
        const menuOptionTyped: BetLottoType = menuOption as BetLottoType
        addLottoListIfConfigExists(BET_SIX, 6, menuOptionTyped)
        addLottoListIfConfigExists(BET_FOUR, 4, menuOptionTyped)
        addLottoListIfConfigExists(BET_THREE, 3, menuOptionTyped)
        addLottoListIfConfigExists(BET_TWO, 2, menuOptionTyped)
        addLottoListIfConfigExists(BET_ONE, 1, menuOptionTyped)

        switch (menuOption) {
          case 'start_number':
            const sNumber = generateSwitchNumber(currentNumber?.[0])
            handleNumbers(sNumber, selected, betTwoKeys)
            break
          case 'end_number':
            const eNumber = generateSwitchNumber(currentNumber?.[0], true)
            handleNumbers(eNumber, selected, betTwoKeys)
            break
          case '19_number':
            const nNumber = generateNineteenNumber(currentNumber?.[0])
            handleNumbers(nNumber, selected, betTwoKeys)
            break
          case 'swap_two':
            const pCurrentBet = [...currentNumber].slice(0, 2)
            const swapTwoNumber = generatePermutations(pCurrentBet)
            handleNumbers(swapTwoNumber, selected, betTwoKeys)
            break
          case 'swap_three':
            const pSwapCurrentBet = [...currentNumber].slice(0, 4)
            const swapThreeNumbers = generatePermutations(pSwapCurrentBet)
            handleNumbers(swapThreeNumbers, selected, betThreeKeys)
            break
        }
      })
      setTimeout(resetNumber, 500)
    }
  }, [currentNumber])

  return {
    renderItem,
    selected,
    onSelectNewMenu,
    maxDigit,
    addDigit,
    deleteNumber,
    currentNumber
  }
}
