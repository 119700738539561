import Banner from './banner.png'
import BgLottoBaac from './bg_lotto_baac.png'
import BgLottoCorporate from './bg_lotto_corporate.png'
import BgLottoCrypto from './bg_lotto_crypto.png'
import BgLottoGovernment from './bg_lotto_goverment.png'
import BgLottoHanoi from './bg_lotto_hanoi.png'
import BgLottoLaostar from './bg_lotto_laostar.png'
import BgLottoMalaysia from './bg_lotto_malaysia.png'
import BgLottoStockChinaAfternoon from './bg_lotto_stock_china_afternoon.png'
import BgLottoStockChinaMorning from './bg_lotto_stock_china_morning.png'
import BgLottoStockDownjones from './bg_lotto_stock_downjones.png'
import BgLottoStockEgypt from './bg_lotto_stock_egypt.png'
import BgLottoStockEngland from './bg_lotto_stock_england.png'
import BgLottoStockGerman from './bg_lotto_stock_german.png'
import BgLottoStockHungsengAfternoon from './bg_lotto_stock_hungseng_afternoon.png'
import BgLottoStockHungsengMorning from './bg_lotto_stock_hungseng_morning.png'
import BgLottoStockIndia from './bg_lotto_stock_india.png'
import BgLottoStockKorea from './bg_lotto_stock_korea.png'
import BgLottoStockLao from './bg_lotto_stock_lao.png'
import BgLottoStockLaoStar from './bg_lotto_stock_lao_star.png'
import BgLottoStockMalaysia from './bg_lotto_stock_malaysia.png'
import BgLottoStockNikei from './bg_lotto_stock_nikei.png'
import BgLottoStockNikeiAfternoon from './bg_lotto_stock_nikei_afternoon.png'
import BgLottoStockRussia from './bg_lotto_stock_russia.png'
import BgLottoStockSingapore from './bg_lotto_stock_singapore.png'
import BgLottoStockTaiwan from './bg_lotto_stock_taiwan.png'
import BgLottoThaiStockAfternoon from './bg_lotto_thaistock_afternoon.png'
import BgLottoThaiStockEvening from './bg_lotto_thaistock_evening.png'
import BgLottoThaiStockMorning from './bg_lotto_thaistock_morning.png'
import BgLottoThaiStockPm from './bg_lotto_thaistock_pm.png'
import BgLottoVietname from './bg_lotto_vietnam.png'
import BgLottoYeekee from './bg_lotto_yeekee.png'
import BgLottoYeekeeVIP from './bg_lotto_yeekeeVIP.png'
import RB7LightPng from './RB7-light.png'
import RB7DarkPng from './RB7-dark.png'

import RB7Dark from './RB7-dark.svg'
import RB7Light from './RB7-light.svg'
import Logo from './logo.svg'
import RightArrow from './Right_Arrow.svg'
import iconAnnouncement from './icon-annoucement.svg'
import iconBack from './icon_back.svg'
import iconBetPollRecent from './icon_bet_poll_recent.svg'
import iconBetPollRecentActive from './icon_bet_poll_recent_active.svg'
import iconBetPollResult from './icon_bet_poll_result.svg'
import iconBetPollResultActive from './icon_bet_poll_result_active.svg'
import iconBetPollSummary from './icon_bet_poll_summary.svg'
import iconBetPollSummaryActive from './icon_bet_poll_summary_active.svg'
import iconBrowseNumberPoll from './icon_browse_number_poll.svg'
import iconBrowseNumberPollActive from './icon_browse_number_poll_active.svg'
import iconBulletLotto from './icon_bullet_lotto.svg'
import iconBulletLottoActive from './icon_bullet_lotto_active.svg'
import iconBulletShootNumber from './icon_bullet_shoot_number.svg'
import iconBulletShootNumberActive from './icon_bullet_shoot_number_active.svg'
import iconCalendarOff from './icon_calendar_off.svg'
import iconCalendarOn from './icon_calendar_on.svg'
import iconChat from './icon_chat.svg'
import iconChatActive from './icon_chat_active.svg'
import iconChinaFlag from './mini-flag-15.svg'
import iconClose from './icon_close.svg'
import iconContactLine from './icon_contact_line.svg'
import iconContactTel from './icon_contact_tel.svg'
import iconDeposit from './icon_deposit.svg'
import iconDepositActive from './icon_deposit_active.svg'
import iconDollar from './icon_dollar.svg'
import iconDollarCoin from './icon_dollar_coin.svg'
import iconEgyptFlag from './mini-flag-21.svg'
import iconEnglandFlag from './mini-flag-23.svg'
import iconEnter from './icon_enter.svg'
import iconFgf from './icon_fgf.svg'
import iconFgfActive from './icon_fgf_active.svg'
import iconGermanFlag from './mini-flag-22.svg'
import iconHeaderDollar from './icon-header-dollar.svg'
import iconHeaderRefresh from './icon-header-refresh.svg'
import iconHome from './icon_home.svg'
import iconHomeActive from './icon_home_active.svg'
import iconJapanFlag from './mini-flag-13.svg'
import iconKoreaFlag from './mini-flag-12.svg'
import iconLaoFlag from './mini-flag-5.svg'
import iconLeftArrow from './icon_left_arrow.svg'
import iconLottoDisable from './icon_lotto_disable.svg'
import iconLottoEnable from './icon_lotto_enable.svg'
import iconLottoFavorite from './icon_lotto_favourite.svg'
import iconMYFlag from './mini-flag-26.svg'
import iconMagnifier from './icon_magnifier.svg'
import iconMagnifierBlack from './icon_Magnifier_black.svg'
import iconNoti from './icon_noti.svg'
import iconNumberDoc from './icon_number_doc.svg'
import iconNumberDocActive from './icon_number_doc_active.svg'
import iconPrevious from './icon-previous.svg'
import iconRepeat from './icon_repeat.svg'
import iconReportCredit from './icon_report_credit.svg'
import iconReportCreditActive from './icon_report_credit_active.svg'
import iconReverse from './icon_reverse.svg'
import iconRightArrow from './icon_right_arrow.svg'
import iconRule from './icon_rule.svg'
import iconRuleActive from './icon_rule_active.svg'
import iconRussiaFlag from './mini-flag-24.svg'
import iconSGFlag from './mini-flag-20.svg'
import iconSelectNumberPoll from './icon_select_number_poll.svg'
import iconSelectNumberPollActive from './icon_select_number_poll_active.svg'
import iconTaiwanFlag from './mini-flag-19.svg'
import iconThFlag from './mini-flag-th.svg'
import iconUSAFlag from './mini-flag-25.svg'
import iconVietnamFlag from './mini-flag-10.svg'
import logoLoading from './logo-loading.png'
import logoYellow from './logo-yellow.svg'
import kIconAnnounce from './@kapook/iconAnnounce.png'
import kIconEnableBet from './@kapook/iconEnableBet.png'
import kIconUnableBet from './@kapook/iconUnableBet.png'
import kThaiFlag from './@kapook/thaiFlag.png'
import kYeekeeFlag from './@kapook/yeekeeFlag.png'
import kVietnamFlag from './@kapook/vietnamFlag.png'
import kGSBFlag from './@kapook/gsbFlag.png'
import kLaoFlag from './@kapook/laosFlag.png'
import kAfternoon from './@kapook/afternoon.png'
import kNoon from './@kapook/noon.png'
import kMorning from './@kapook/morning.png'
import kGermanFlag from './@kapook/germanFlag.png'
import kMalaysiaFlag from './@kapook/malaysiaFlag.png'
import kJapanFlag from './@kapook/japanFlag.png'
import kUSAFlag from './@kapook/usaFlag.png'
import kHangsengFlag from './@kapook/hangsengFlag.png'
import kUKFlag from './@kapook/ukFlag.png'
import kEgyptFlag from './@kapook/egyptFlag.png'
import kSingaporeFlag from './@kapook/singaporeFlag.png'
import kKoreaFlag from './@kapook/koreaFlag.png'
import kIndiaFlag from './@kapook/indiaFlag.png'
import kChinaFlag from './@kapook/chinaFlag.png'
import kTaiwanFlag from './@kapook/taiwanFlag.png'
import kRussiaFlag from './@kapook/russiaFlag.png'
import { ReactComponent as kBetIcon } from './@kapook/svg/iconBet.svg'
import { ReactComponent as kBetIconActive } from './@kapook/svg/iconBetActive.svg'
import { ReactComponent as kRuleIcon } from './@kapook/svg/iconRule.svg'
import { ReactComponent as kRuleIconActive } from './@kapook/svg/iconRuleActive.svg'
import { ReactComponent as kShootIcon } from './@kapook/svg/iconShoot.svg'
import { ReactComponent as kShootIconActive } from './@kapook/svg/iconIconShootActive.svg'
export const KapookSvg = {
  kBetIcon,
  kBetIconActive,
  kRuleIcon,
  kRuleIconActive,
  kShootIcon,
  kShootIconActive
}
export const Kapook = {
  kIconAnnounce,
  kIconEnableBet,
  kIconUnableBet,
  kThaiFlag,
  kYeekeeFlag,
  kVietnamFlag,
  kGSBFlag,
  kLaoFlag,
  kAfternoon,
  kMorning,
  kGermanFlag,
  kMalaysiaFlag,
  kJapanFlag,
  kUSAFlag,
  kHangsengFlag,
  kUKFlag,
  kEgyptFlag,
  kNoon,
  kSingaporeFlag,
  kKoreaFlag,
  kIndiaFlag,
  kChinaFlag,
  kTaiwanFlag,
  kRussiaFlag
}

export const Svg = {
  Logo,
  RightArrow,
  iconAnnouncement,
  iconBack,
  iconBetPollRecent,
  iconBetPollRecentActive,
  iconBetPollResult,
  iconBetPollResultActive,
  iconBetPollSummary,
  iconBetPollSummaryActive,
  iconBrowseNumberPoll,
  iconBrowseNumberPollActive,
  iconBulletLotto,
  iconBulletLottoActive,
  iconBulletShootNumber,
  iconBulletShootNumberActive,
  iconCalendarOff,
  iconCalendarOn,
  iconChat,
  iconChatActive,
  iconClose,
  iconContactLine,
  iconContactTel,
  iconDeposit,
  iconDepositActive,
  iconDollar,
  iconDollarCoin,
  iconEnter,
  iconFgf,
  iconFgfActive,
  iconHeaderDollar,
  iconHeaderRefresh,
  iconHome,
  iconHomeActive,
  iconLeftArrow,
  iconLottoDisable,
  iconLottoEnable,
  iconLottoFavorite,
  iconMagnifier,
  iconMagnifierBlack,
  iconNoti,
  iconNumberDoc,
  iconNumberDocActive,
  iconPrevious,
  iconRepeat,
  iconReportCredit,
  iconReportCreditActive,
  iconReverse,
  iconRightArrow,
  iconRule,
  iconRuleActive,
  iconSelectNumberPoll,
  iconSelectNumberPollActive,
  iconThFlag,
  iconLaoFlag,
  iconVietnamFlag,
  iconChinaFlag,
  iconKoreaFlag,
  iconJapanFlag,
  iconTaiwanFlag,
  iconEgyptFlag,
  iconGermanFlag,
  iconEnglandFlag,
  iconRussiaFlag,
  iconSGFlag,
  iconUSAFlag,
  iconMYFlag,
  logoYellow,
  RB7Dark,
  RB7Light
}
export const Img = {
  Banner,
  BgLottoBaac,
  BgLottoCorporate,
  BgLottoCrypto,
  BgLottoGovernment,
  BgLottoHanoi,
  BgLottoLaostar,
  BgLottoMalaysia,
  BgLottoStockChinaAfternoon,
  BgLottoStockChinaMorning,
  BgLottoStockDownjones,
  BgLottoStockEgypt,
  BgLottoStockEngland,
  BgLottoStockGerman,
  BgLottoStockHungsengAfternoon,
  BgLottoStockHungsengMorning,
  BgLottoStockIndia,
  BgLottoStockKorea,
  BgLottoStockLao,
  BgLottoStockLaoStar,
  BgLottoStockMalaysia,
  BgLottoStockNikei,
  BgLottoStockNikeiAfternoon,
  BgLottoStockRussia,
  BgLottoStockSingapore,
  BgLottoStockTaiwan,
  BgLottoThaiStockAfternoon,
  BgLottoThaiStockEvening,
  BgLottoThaiStockMorning,
  BgLottoThaiStockPm,
  BgLottoVietname,
  BgLottoYeekee,
  BgLottoYeekeeVIP,
  logoLoading,
  RB7DarkPng,
  RB7LightPng
}
