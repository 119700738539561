import ManualBet from './manual-bet'
import Navbar, { NavItem } from 'components/@kapook/Navbar/KNavbarBetPage'
import BetFromPanel from './bet-from-panel/@kapook/KBetFromPanal'
import { LottoBetType } from 'types/navbar'
import { useNavBar } from 'components/Navbar/useNavbar'
import { useTranslation } from 'react-i18next'
import { ResultItem } from 'components/ResultCard'
import BetPageContainer from 'components/@kapook/Container/BetPageContainer'
interface BetLottoPageProps {
  signature?: string
}
const BetLottoPage = ({ signature }: BetLottoPageProps) => {
  const { t } = useTranslation()
  const { selected, setSelectedItem } =
    useNavBar<LottoBetType>('choose_from_panel')
  const renderTab = () => {
    if (selected === 'manual_bet') {
      return <ManualBet />
    } else {
      return <BetFromPanel />
    }
  }
  const NaveBetLottoItem: NavItem[] = [
    {
      name: t('betLottoPage.nav.choose_from_panel'),
      key: 'choose_from_panel'
    },
    {
      name: t('betLottoPage.nav.manual_bet'),
      key: 'manual_bet'
    }
  ]
  return (
    <div>
      <Navbar
        navBarClass="d-flex justify-content-start"
        items={NaveBetLottoItem}
        selected={selected}
        onChangeItem={setSelectedItem}
      />
      <BetPageContainer>
        {signature && (
          <center>
            {' '}
            <ResultItem
              title={t('betResult.signature') ?? ''}
              result={signature}
            />
          </center>
        )}
        <div className="tab-content" id="pills-tabContent">
          {renderTab()}
        </div>
      </BetPageContainer>
    </div>
  )
}

export default BetLottoPage
