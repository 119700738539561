import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from 'images'

const SwapButton = styled.button<{ active?: boolean }>`
  display: flex;
  flex-grow: 1;
  width: 100%;
  background-color: ${(props) => (props.active ? '#f1b625' : 'transparent')};
  color: ${(props) => (props.active ? '#fff' : '#333')};
  border: 1px solid ${(props) => (props.active ? '#f1b625' : '#ddd')};
  padding: 10px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  font-size: 16px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    border-color 0.3s ease;

  img {
    width: 20px;
    height: auto;
  }

  &:hover {
    background-color: ${(props) => (props.active ? '#e0a91d' : '#f0f0f0')};
    border-color: ${(props) => (props.active ? '#e0a91d' : '#ccc')};
  }
`
type SwapNumberButtonProps = {
  swapNumber?: boolean
  onToggleSwapNumber?: () => void
}
const SwapNumberButton = ({
  swapNumber,
  onToggleSwapNumber
}: SwapNumberButtonProps) => {
  const { t } = useTranslation()

  return (
    <SwapButton active={swapNumber} onClick={() => onToggleSwapNumber?.()}>
      <img
        src={Svg.iconReverse}
        alt={t('layout.lottoBetTab.rotateNumber').toString()}
      />
      {t('layout.lottoBetTab.rotateNumber')}
    </SwapButton>
  )
}

export default SwapNumberButton
