import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { LottoType, LottoItemProps } from 'components/LottoItem'
import dayjs, { isValidDate, useCountDown } from 'utils/date-time-utils'
import { Kapook } from 'images'

const flagIcons: { [key in LottoType]?: string } = {
  'lotto-list-goverment': Kapook.kThaiFlag,
  'lotto-list-thaistock-afternoon': Kapook.kThaiFlag,
  'lotto-list-thaistock-evening': Kapook.kThaiFlag,
  'lotto-list-thaistock-morning': Kapook.kThaiFlag,
  'lotto-list-thaistock-pm': Kapook.kThaiFlag,
  'lotto-list-yeekeevip': Kapook.kYeekeeFlag,
  'lotto-list-yeekee': Kapook.kYeekeeFlag,
  'lotto-list-crypto': Kapook.kYeekeeFlag,
  'lotto-list-vietnam': Kapook.kVietnamFlag,
  'lotto-list-hanoi': Kapook.kVietnamFlag,
  'lotto-list-baac': Kapook.kGSBFlag,
  'lotto-list-gsb': Kapook.kGSBFlag,
  'lotto-list-stock-lao-star': Kapook.kLaoFlag,
  'lotto-list-stock-lao': Kapook.kLaoFlag,
  'lotto-list-stock-german': Kapook.kGermanFlag,
  'lotto-list-stock-malaysia': Kapook.kMalaysiaFlag,
  'lotto-list-stock-nikei': Kapook.kJapanFlag,
  'lotto-list-stock-nikei-afternoon': Kapook.kJapanFlag,
  'lotto-list-stock-downjones': Kapook.kUSAFlag,
  'lotto-list-stock-hungseng-morning': Kapook.kHangsengFlag,
  'lotto-list-stock-hungseng-afternoon': Kapook.kHangsengFlag,
  'lotto-list-stock-england': Kapook.kUKFlag,
  'lotto-list-stock-egypt': Kapook.kEgyptFlag,
  'lotto-list-stock-singapore': Kapook.kSingaporeFlag,
  'lotto-list-stock-korea': Kapook.kKoreaFlag,
  'lotto-list-stock-india': Kapook.kIndiaFlag,
  'lotto-list-stock-china-morning': Kapook.kChinaFlag,
  'lotto-list-stock-china-afternoon': Kapook.kChinaFlag,
  'lotto-list-stock-taiwan': Kapook.kTaiwanFlag,
  'lotto-list-stock-russia': Kapook.kRussiaFlag,
  'lotto-list-corporate': Kapook.kYeekeeFlag
}
const subFlagIcons: { [key in LottoType]?: string } = {
  'lotto-list-thaistock-afternoon': Kapook.kAfternoon,
  'lotto-list-thaistock-evening': Kapook.kAfternoon,
  'lotto-list-thaistock-pm': Kapook.kNoon,
  'lotto-list-thaistock-morning': Kapook.kMorning,
  'lotto-list-stock-nikei-afternoon': Kapook.kAfternoon,
  'lotto-list-stock-nikei': Kapook.kMorning,
  'lotto-list-stock-hungseng-morning': Kapook.kMorning,
  'lotto-list-stock-hungseng-afternoon': Kapook.kAfternoon,
  'lotto-list-stock-china-afternoon': Kapook.kAfternoon,
  'lotto-list-stock-china-morning': Kapook.kMorning
}
const CardWrapperContainer = styled.div`
  @media (max-width: 340px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`
const CardWrapper = styled.div<{ betEnable?: boolean }>`
  background: ${(props) =>
    props.betEnable
      ? 'linear-gradient(to bottom, #f8d78a 0%, #f7c248 100%)'
      : '#d3d3d3'};
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  height: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  filter: ${(props) => (props.betEnable ? 'none' : 'grayscale(100%)')};
  cursor: pointer;
  }

  &:hover {
    background: ${(props) =>
      props.betEnable
        ? 'linear-gradient(to bottom, #f8c048 0%, #f5a835 100%)'
        : '#bdbdbd'};
    transform: scale(1.02);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  @media (max-width: 425px) {
    justify-content: flex-start;
  }
`
const Title = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  color: #000;

  @media (max-width: 425px) {
    font-size: 0.75rem;
  }
`

const DetailsContent = styled.div<{
  hasDetails?: boolean
  betEnable?: boolean
}>`
  flex: 1;
  background-color: ${(props) => (props.hasDetails ? '#fff' : 'transparent')};
  padding: 0.5rem;
  border-radius: ${(props) => (props.hasDetails ? '8px' : '0')};
  box-shadow: ${(props) =>
    props.hasDetails ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none'};
  min-height: 40px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 425px) {
    font-size: 0.75rem;
  }
`

const DetailsWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0;
`

const FlagContainer = styled.div`
  flex-shrink: 0;
  max-width: 60px;
  height: auto;
  margin-left: 10px;
  position: relative;

  @media (max-width: 425px) {
    display: none;
  }
`

const FlagImage = styled.img`
  width: 58px;
  height: auto;
  z-index: 2;
  position: relative;

  @media (max-width: 425px) {
    width: 35px;
    height: 35px;
`

const SmallFlagContainer = styled(FlagContainer)`
  display: none;

  @media (max-width: 425px) {
    display: block;
    width: 35px;
    margin-right: 8px;
  }
`

const SubFlag = styled.img`
  position: absolute;
  width: 48px;
  max-height: 50px;
  top: -15px;
  right: -10px;
  z-index: 1;

  @media (max-width: 425px) {
    width: 20px;
    max-height: 25px;
    top: -10px;
    right: -5px;
  }
`

const CountdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  width: 100%;
`

const CountdownTimer = styled.p<{ enabled?: boolean }>`
  background-color: ${(props) => (props.enabled ? '#fff' : '#000')};
  color: ${(props) => (props.enabled ? '#0ecb81' : '#fff')};
  padding: 0.5rem;
  border-radius: 12px;
  font-size: 0.875rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`

const ClockIcon = styled.img`
  width: 1rem;
  height: 1rem;
`

const ButtonOpen = styled.button`
  background-color: #fff;
  color: #0ecb81;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  cursor: pointer;
  border: none;
`

const ButtonClose = styled.button`
  background-color: #000;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  margin-top: 0.5rem;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
`

const CloseIcon = styled.img`
  width: 1rem;
  height: 1rem;
`

const LottoItem = ({
  title,
  details,
  type,
  betEnable,
  betDetailsString,
  dueDate,
  openDate,
  endDate,
  link,
  handleOnClick
}: LottoItemProps) => {
  const { t } = useTranslation()
  const navigation = useNavigate()

  const renderDetails = () => {
    if (dueDate) {
      return <CountDownComponent dueDate={dueDate} betEnable={betEnable} />
    }

    if (
      (openDate &&
        endDate &&
        dayjs(openDate).isBefore(dayjs()) &&
        dayjs(endDate).isBefore(dayjs())) ||
      !betEnable
    ) {
      return (
        <ButtonClose disabled>
          <CloseIcon src={Kapook.kIconUnableBet} alt="Close Icon" />
          {t('lottoPage.status.close')}
        </ButtonClose>
      )
    }

    return <ButtonOpen>{t('lottoPage.status.open')}</ButtonOpen>
  }

  const handleCardClick = () => {
    if (link) {
      navigation(link)
    } else if (handleOnClick) {
      handleOnClick()
    }
  }

  const flagSrc = flagIcons[type] ?? ''
  const subFlagSrc = subFlagIcons[type] ?? undefined

  return (
    <CardWrapperContainer
      className="col-6 col-lg-3 mb-4 col"
      onClick={handleCardClick}
    >
      <CardWrapper betEnable={betEnable}>
        <TitleContainer>
          <SmallFlagContainer>
            {subFlagSrc && <SubFlag src={subFlagSrc} alt="Sub Flag" />}
            <FlagImage src={flagSrc} alt={title} />
          </SmallFlagContainer>
          <Title>{title}</Title>
        </TitleContainer>
        <DetailsWrapper>
          <DetailsContent hasDetails={!!details} betEnable={betEnable}>
            {details}
          </DetailsContent>

          {flagSrc && (
            <FlagContainer>
              {subFlagSrc && <SubFlag src={subFlagSrc} alt="Sub Flag" />}
              <FlagImage src={flagSrc} alt={title} />
            </FlagContainer>
          )}
        </DetailsWrapper>

        {renderDetails()}
      </CardWrapper>
    </CardWrapperContainer>
  )
}

const CountDownComponent = ({
  dueDate,
  betEnable
}: {
  dueDate: string
  betEnable?: boolean
}) => {
  const { t } = useTranslation()
  if (!isValidDate(dueDate)) {
    return <p className="text-gold">Invalid due date</p>
  }
  const [hours, minutes, seconds, loading, isOverdue] = useCountDown(dueDate)

  if (loading) {
    return <CountdownTimer enabled={betEnable}>Loading...</CountdownTimer>
  }

  if (isOverdue || !betEnable) {
    return (
      <ButtonClose>
        <CloseIcon src={Kapook.kIconUnableBet} alt="Close Icon" />
        {t('lottoPage.status.close')}
      </ButtonClose>
    )
  }

  return (
    <CountdownWrapper>
      <CountdownTimer enabled={betEnable}>
        <ClockIcon src={Kapook.kIconEnableBet} />
        {hours} : {minutes} : {seconds}
      </CountdownTimer>
    </CountdownWrapper>
  )
}

export default LottoItem
