// import { Navbar } from 'components'
import Navbar from 'components/@kapook/Navbar/KNavbar'
import LottoLayout from 'components/@kapook/LottoLayout/KLottoLayout'
import useLottoBetPage from './hook/useLottoBetPage'
import YeekeeResult from './yeekee/yeekee-result'
import { useEffect } from 'react'

const LottoBetPage = () => {
  const {
    isYeekeeResulted,
    round,
    getTitlePage,
    loading,
    lottoNaveItem,
    selected,
    setSelectedItem,
    renderSubPage,
    isRoundClosed,
    isYeekee,
    onNextRound,
    onPrevRound,
    betRuleData,
    details,
    shootTime
  } = useLottoBetPage()
  useEffect(() => {
    if (isRoundClosed && isYeekee && !isYeekeeResulted) {
      setSelectedItem('pills-shoot-lotto-number-tab')
    }
  }, [isRoundClosed, isYeekee, isYeekeeResulted])
  const renderContent = () => {
    if (!isYeekeeResulted) {
      return renderSubPage()
    } else {
      return <YeekeeResult />
    }
  }

  return (
    <LottoLayout
      title={getTitlePage()}
      dueDate={
        selected == 'pills-shoot-lotto-number-tab'
          ? shootTime
          : round?.close_at ?? ''
      }
      selectedPage={selected}
      loading={loading}
      onNext={onNextRound}
      onPrev={onPrevRound}
      isRoundClose={isRoundClosed}
      betRuleData={betRuleData}
      details={details}
      navbar={
        <Navbar
          items={lottoNaveItem}
          selected={selected}
          onChangeItem={setSelectedItem}
          navBarClass="main-nav-bullet-container"
        />
      }
    >
      {renderContent()}
    </LottoLayout>
  )
}
export default LottoBetPage
