import styled from 'styled-components'
// import BetDetailsPanel from 'components/BetDetailsPanel'
import BetDetailsPanel from 'components/@kapook/BetDetailPanel/KbetDetailPanel'
import BetFooter, { BetFooterProps } from 'components/BetFooter'
import CountdownClock from 'components/@kapook/CountdownClock/KCountdownClock'
import Layout from 'components/Layout/Layout'
import { PageTitleProps } from 'components/PageTitle'
import { LottoContextProvider } from 'contexts'
import BetRulePanel from 'components/@kapook/BetRateTable/KBetRateTable'
import { LottoNavType } from 'types/navbar'
import { useTranslation } from 'react-i18next'
import { FixTypeLater } from 'react-redux'

export interface KLottoPageLayoutProps extends PageTitleProps, BetFooterProps {
  dueDate: string
  selectedPage?: LottoNavType
  children?: React.ReactNode
  loading?: boolean
  isRoundClose?: boolean
  betRuleData?: FixTypeLater
  navbar?: React.ReactNode
}

const KLottoPageLayoutContainer = styled.div`
  .bet-dashboard {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .header {
    background-color: #3c3c3c;
    color: #fff;
    padding: 10px;
    margin-bottom: 0;
    text-align: center;
    position: relative;
  }

  .main-nav-bullet-container {
    margin-top: 0;
    padding-top: 0;
  }

  .navbar-container {
    margin: 0;
    padding: 0;
    background-color: #3c3c3c;
    border-radius: 0;
  }

  .header-title {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
  }

  .header-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #f1b625;
  }

  .header-details {
    color: #d35400;
    font-size: 16px;
  }

  .countdown {
    background-color: #444;
    color: #fff;
    padding: 15px;
    text-align: center;
    border-radius: 8px;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .bet-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;

    @media (min-width: 768px) {
      margin-left: 20px;
      margin-top: 0;
    }
  }

  .bet-footer {
    margin-top: 20px;
  }

  .round-navigation {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    margin-bottom: 15px;
  }

  .round-button {
    background-color: #f1b625;
    color: #333;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f7c248;
    }
  }
`

const KLottoPageLayout = (props: KLottoPageLayoutProps) => {
  const { t } = useTranslation()

  const renderBetPanel = () => {
    if (props.isRoundClose) {
      return <></>
    }
    if (props.selectedPage === 'pills-bet-rules-tab') {
      return (
        <div className="col-12 col-lg-4 mt-4">
          <BetRulePanel data={props?.betRuleData ?? []} />
        </div>
      )
    }
    if (props.selectedPage === 'pills-bet-lotto-tab') {
      return (
        <div className="col-md-12 col-lg-4 ">
          <BetDetailsPanel />
        </div>
      )
    }
  }
  const getClockTitle = () => {
    if (props.selectedPage !== 'pills-shoot-lotto-number-tab') {
      return t('layout.lottoPage.clockText')
    } else {
      return t('shootNumber.label')
    }
  }

  return (
    <Layout loading={props.loading}>
      <LottoContextProvider>
        <KLottoPageLayoutContainer className="bet-dashboard-container container mt-4 mb-4">
          <div className="header">
            <h2 className="header-title">{props.title}</h2>
            <p className="header-details">{props.details}</p>
          </div>
          {props.navbar && (
            <div className="main-nav-bullet-container">{props.navbar}</div>
          )}
          <div className="countdown-container">
            <CountdownClock title={getClockTitle()} dueDate={props.dueDate} />
          </div>
          <div className="row">
            <div
              className={`col-lg-8 ${
                props?.selectedPage === 'pills-shoot-lotto-number-tab' ||
                props.isRoundClose
                  ? 'col-lg-12'
                  : 'col-lg-8'
              }`}
            >
              {props.children}
            </div>
            {renderBetPanel()}
          </div>
        </KLottoPageLayoutContainer>
      </LottoContextProvider>
    </Layout>
  )
}

export default KLottoPageLayout
