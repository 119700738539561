import LottoBetTabLayout from 'components/Layout/LottoBetTabLayout'
import { useThreeNumber } from 'pages/lotto/hook/useThreeNumber'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import DisabledPanel from './diabled-panal'
import React from 'react'
import KListPanel from './@kapook/KListPanel'
import KNumberGrid from './@kapook/KNumberGrid'
export const MutatedInput = styled.input`
  caret-color: transparent;
  cursor: pointer;
`
export const PanelButton = styled.a`
  cursor: pointer;
  &.limit,
  &.limit:hover {
    background-color: #000 !important;
    cursor: not-allowed;
  }
  &.range {
    background-color: #ffc302;
  }
`
const ThreeDigitPanel = () => {
  const { t } = useTranslation()
  const {
    numbers,
    onToggleParent,
    menuSelected,
    onToggleMenu,
    onClickNumber,
    swapNumber,
    onToggleSwapNumber,
    searchDigit,
    onSearchDigit,
    getLimitTypeUI,
    betThree
  } = useThreeNumber()
  const renderPanel = () => {
    const renderElement: React.ReactNode[] = []
    Object.keys(betThree).forEach((key) => {
      renderElement.push(
        <div className="col-12 col-lg-6" key={key}>
          <div
            className={`input-group mb-3 search-lottobox ${
              menuSelected.includes(key) && 'active'
            }`}
            onClick={() => onToggleMenu(key)}
          >
            <MutatedInput
              type="text"
              className="form-control"
              autoFocus={false}
              readOnly
              aria-describedby="inputGroup-sizing-default"
              placeholder={t(`betDetailPanel.betLottoType.${key}`).toString()}
            />
            <span className="input-group-text" id="inputGroup-sizing-default">
              {betThree[key]}
            </span>
          </div>
        </div>
      )
    })
    return renderElement
  }
  return (
    <>
      <LottoBetTabLayout
        searchDigit={searchDigit}
        onSearchDigit={onSearchDigit}
        swapNumber={swapNumber}
        onToggleSwapNumber={onToggleSwapNumber}
      />
      <div className="row mb-2 lotto-win-rate-container">{renderPanel()}</div>

      <div className="select-lotto-number-container">
        {menuSelected.length == 0 && <DisabledPanel />}
        <div className="overlay-paddle"></div>
        <div className="row mb-2 select-hundred-number">
          <div className="col-12">
            <KListPanel numbers={numbers} onToggleParent={onToggleParent} />
            <hr />
            <div className="row mb-2 selct-main-unit-number">
              <div className="col-12">
                <KNumberGrid
                  numbers={
                    numbers.find((i) => i.active ?? i.parent === '000')
                      ?.children ?? []
                  }
                  onClickNumber={onClickNumber}
                  getLimitTypeUI={getLimitTypeUI}
                  menuSelected={menuSelected}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ThreeDigitPanel
