import ResultCard from 'components/@kapook/CardResult/KCardResult'
import { ResultItemProps } from 'components/ResultCard'
interface ResultedProps {
  title: string
  details: string
  icon: string
  items: ResultItemProps[]
  gameType?: string
  noRound?: boolean
  headerResult?: string
  header?: string
  onClickHeader?: () => void
  swiper?: boolean
}
const Resulted = ({
  title,
  details,
  icon,
  items,
  gameType,
  noRound,
  headerResult,
  header,
  onClickHeader,
  swiper
}: ResultedProps) => {
  return (
    <>
      {gameType != 'yekee' && (
        <ResultCard
          onClickHeader={onClickHeader}
          noRound={noRound}
          title={title}
          detail={details}
          icon={icon}
          results={items}
          header={header}
          headerResult={headerResult}
          swiper={swiper}
        />
      )}
    </>
  )
}
export default Resulted
