import { FixTypeLater } from 'react-redux'
import styled from 'styled-components'

export type NavBarItemProps = {
  key: string
  name: string
  itemClass?: string
  active?: boolean
  onChangeItem?: (val: FixTypeLater) => void
}

export interface NavbarProps {
  selected?: string
  onChangeItem: (key: FixTypeLater) => void
  navBarClass?: string
  items: NavBarItemProps[]
}

const NavbarContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  background-color: transparent;
`

const NavbarButton = styled.button<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#f1b625' : '#fff')};
  border: 1px solid ${(props) => (props.active ? '#f1b625' : '#ddd')};
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  color: ${(props) => (props.active ? '#000' : '#333')};
  transition: all 0.3s ease;
  flex: 1;

  &:hover {
    background-color: #f1b625;
    border-color: #f1b625;
    color: #000;
  }
`

const NavbarTabItem = ({
  active = false,
  name,
  itemClass,
  onChangeItem
}: NavBarItemProps) => {
  return (
    <NavbarButton className={itemClass} active={active} onClick={onChangeItem}>
      {name}
    </NavbarButton>
  )
}

const KNavbarBetFromPanel = ({
  items,
  onChangeItem,
  selected,
  navBarClass
}: NavbarProps) => {
  return (
    <NavbarContainer className={navBarClass}>
      {items.map((i) => (
        <NavbarTabItem
          key={i.key}
          active={selected === i.key}
          name={i.name}
          itemClass={i.itemClass}
          onChangeItem={() => onChangeItem(i.key)}
        />
      ))}
    </NavbarContainer>
  )
}

export default KNavbarBetFromPanel
