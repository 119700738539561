import { Svg } from 'images'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
`

const Input = styled.input`
  border-radius: 8px 0 0 8px;
  border-color: rgba(8, 15, 24, 0.16);
  flex: 1;
  padding: 12px;
  min-width: 0;
  font-size: 16px;
`

const Button = styled.button`
  background-color: #f1b625;
  border-color: transparent;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 8px 8px 0;
  cursor: pointer;

  &:hover {
    background-color: #e0a621;
    border-color: transparent;
  }

  img {
    width: 20px;
    height: auto;
  }
`

type LottoSearchProps = {
  value?: string
  onSearch?: (e: ChangeEvent<HTMLInputElement>) => void
}

const LottoSearch = ({ value, onSearch }: LottoSearchProps) => {
  const { t } = useTranslation()
  return (
    <SearchContainer>
      <Input
        type="search"
        id="search-lotto-number"
        className="form-control"
        placeholder={t('lottoSearch.searchNumber').toString()}
        onChange={onSearch}
        value={value ?? ''}
      />
      <Button type="button" className="btn btn-primary">
        <img
          src={Svg.iconMagnifierBlack}
          alt={t('lottoSearch.searchNumber').toString()}
        />
      </Button>
    </SearchContainer>
  )
}

export default LottoSearch
