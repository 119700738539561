import styled from 'styled-components'
import { Svg } from 'images'

export type KLottoFilterProps = {
  menus: { key: number; value: string | number }[]
  selected: string[]
  onSelect: (menu: string) => void
  onClear?: () => void
}

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  margin: 10px auto;
`

const FilterButton = styled.button<{ isSelected: boolean }>`
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 0.875rem;
  cursor: pointer;
  border: 2px solid ${(props) => (props.isSelected ? '#f1b625' : '#ccc')};
  background-color: ${(props) => (props.isSelected ? '#f1b625' : '#fff')};
  color: ${(props) => (props.isSelected ? '#000' : '#333')};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.isSelected ? '#f1b625' : '#f0f0f0')};
    border-color: ${(props) => (props.isSelected ? '#f1b625' : '#bbb')};
  }
`

const ClearButton = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;

  img {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: rotate(90deg);
  }
`

const KLottoFilter = ({
  menus,
  selected,
  onSelect,
  onClear
}: KLottoFilterProps) => {
  return (
    <FilterContainer className="container">
      {menus?.map((i) => (
        <FilterButton
          key={i.key}
          isSelected={selected.includes(i.key.toString())}
          onClick={() => onSelect(i.key.toString())}
        >
          {i.value}
        </FilterButton>
      ))}
      {selected.length > 0 && (
        <ClearButton onClick={() => onClear?.()}>
          <img src={Svg.iconClose} alt="Clear selection" />
        </ClearButton>
      )}
    </FilterContainer>
  )
}

export default KLottoFilter
