import PollStatus, { PollStatusProps } from 'components/PollStatus'
import { Svg } from 'images'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NumberFormat } from 'utils/number-utils'
export interface PollItemProps extends PollStatusProps {
  pollNumber: string
  details?: string
  gameDate: string
  betAmount: number
  winAmount: number
  canCancel?: boolean
  onClickDetail?: () => void
  onCancel?: () => void
  bonus: number
  isPrimary?: boolean
}

export const WinStatus = ({
  isWin,
  children
}: {
  isWin?: boolean
  children: React.ReactNode
}) => {
  return <span className={isWin ? 'lotto-win' : 'lotto-lost'}>{children}</span>
}
const PollItem = ({
  pollNumber,
  details = '-',
  status,
  betAmount,
  winAmount,
  gameDate,
  canCancel,
  onCancel,
  onClickDetail,
  bonus,
  isPrimary
}: PollItemProps) => {
  const { t } = useTranslation()
  return (
    <div className="container bet-user-poll-detail-container container-w-border-shadow">
      <div className="row">
        <div className="col-12">
          <p className="mb-2">
            <strong>{t('pollItem.pollNumber')}</strong>
            <span>{pollNumber}</span>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <p className="mb-2">
            <strong>{t('pollItem.list')}</strong>
            <span>{details}</span>
          </p>
        </div>
        <div className="col-12 col-lg-5">
          <p className="mb-2">
            <strong>{t('pollItem.betDate')}</strong>
            <span>{gameDate}</span>
          </p>
        </div>
        {isPrimary && status === 'settled' && bonus > 0 && (
          <div className="col-12 col-lg-3">
            <p className="mb-2">
              <strong>{t('pollItem.bonus')}</strong>
              <span>{NumberFormat(bonus)}</span>
            </p>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-12 col-lg-3">
          <p className="mb-2">
            <strong>{t('pollItem.betAmount')}</strong>
            <span>{NumberFormat(betAmount)}</span>
          </p>
        </div>
        <div className="col-12 col-lg-3">
          <p className="mb-2 mb-2 fs-6 fs-md-5">
            <strong>{t('pollItem.winAmount')}</strong>
            <WinStatus isWin={winAmount > 0}>
              {NumberFormat(winAmount)}
            </WinStatus>
          </p>
        </div>
        <div className="col-12 col-lg-3">
          <p className="mb-2">
            <strong>{t('pollItem.status')}</strong>
            <PollStatus status={status} />
          </p>
        </div>
        <div className="col-12 col-lg-3">
          <p className="mb-2 ">
            <a
              title={t('pollItem.details').toString()}
              className="text-gold text-decoration-none"
              onClick={() => onClickDetail?.()}
            >
              <img src={Svg.iconNoti} title="x" />
              {t('pollItem.details')}
            </a>
          </p>
          {['approved'].includes(status) && canCancel && (
            <p className="mb-2">
              <a
                title={t('pollStatus.rejected').toString()}
                className="text-red"
                onClick={() => onCancel?.()}
              >
                {t('pollStatus.rejected')}
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default PollItem
