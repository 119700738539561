import { GenericWidgetProps } from 'components/GenericWidget'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'redux/reducers'
import { useEffect, useState } from 'react'
import { LottoItemProps } from 'components/LottoItem'
import { convertLottoType, isYeekeeType } from 'utils/lotto-type-utils'
import { LottoListProps } from 'components/LottoList'
import { sagaActions } from 'redux/sagaActions'
import { toast } from 'react-hot-toast'
import { Group } from 'redux/reducers/gameReducer'
import { useTranslation } from 'react-i18next'
import {
  DATE_TIME_FORMAT_HUMAN_SHORT,
  convertToDateTime
} from 'utils/date-time-utils'
import { useResultSlider } from 'pages/lotto/hook/useResultSlider'
import { createSelector } from '@reduxjs/toolkit'
const selectGroups = createSelector(
  (state: RootState) => state.games,
  (games) => ({
    groups: games.groups,
    loading: games.loading.groups,
    announcements: games.announcements,
    error: games.error
  })
)
export const useHome = () => {
  const { i18n, t } = useTranslation()
  const dispatch = useDispatch()
  const { groups, loading, error, announcements } = useSelector(selectGroups)
  const { swiperProps } = useResultSlider()
  const [renderGroup, SetGroups] = useState<Group[]>(groups)
  const [selectFilter, SetFilter] = useState<Set<string>>(new Set())
  const onSetFilter = (newMenu: string) => {
    const updatedFilters = new Set(selectFilter)
    if (updatedFilters?.has(newMenu)) {
      updatedFilters.delete(newMenu)
    } else {
      updatedFilters?.add(newMenu)
    }
    SetFilter(updatedFilters)
  }
  useEffect(() => {
    setNewRenderGroup()
  }, [groups])
  const menus = [...groups].map((i) => ({
    key: i.id,
    value: i18n.language === 'th' ? i.name_th : i.name_en
  }))

  useEffect(() => {
    dispatch({
      type: sagaActions.FETCH_GROUPS
    })
    dispatch({
      type: sagaActions.FETCH_ANNOUNCEMENTS
    })
    if (error) {
      toast.error(error)
      dispatch({ type: sagaActions.CLEAR_GAME_ERROR })
      dispatch({ type: sagaActions.CLEAR_GLOBAL_ERROR })
    }
  }, [, error])
  const setNewRenderGroup = () => {
    const selected = [...selectFilter]
    let pGroup = [...groups]
    if (selected.length > 0) {
      pGroup = [
        ...[...groups].filter((i) => selected.includes(i.id.toString()))
      ]
    }
    SetGroups(pGroup)
  }
  const onClear = () => {
    const updatedFilters = new Set(selectFilter)
    updatedFilters.clear()
    SetFilter(updatedFilters)
  }
  useEffect(() => {
    setNewRenderGroup()
  }, [JSON.stringify([...selectFilter])])
  const genericWidgets: GenericWidgetProps[] = renderGroup
    ?.filter((i) => i.games.length > 0)
    .map((group) => {
      const listLottoProps: LottoListProps = {
        title:
          localStorage.getItem('lang') === 'th' ? group.name_th : group.name_en,
        listLotto: group.games
          .map((i) => {
            const resp: LottoItemProps = {
              gameId: i.id,
              title:
                localStorage.getItem('lang') === 'th' ? i.name_th : i.name_en,
              betEnable: i.is_enable === 'yes' ? true : false,
              type: convertLottoType(i.flag),
              link: `${i.code}/${i.id}`
            }
            if (i.rounds.length === 1) {
              resp.openDate = i.rounds?.[0].open_at
              resp.dueDate = i.rounds?.[0].close_at
              const closeAtTxt = convertToDateTime(
                i.rounds?.[0].close_at,
                DATE_TIME_FORMAT_HUMAN_SHORT,
                localStorage.getItem('lang') ?? i18n.language
              )
              const detailText = t('lottoPage.status.close_at', {
                date: closeAtTxt
              })
              resp.details = detailText
              resp.link = `${i.code}/${i.id}/${i.rounds?.[0]?.id}`
            }
            if (['yeekee', 'yeekee_vip', 'encrypt_game'].includes(i.type)) {
              const openPrefix = t('lottoPage.status.open')
              const twentyFourHours = t('lottoPage.status.24hr')
              resp.details = `${openPrefix} ${twentyFourHours}`
            }

            if (i.rounds.length === 0) {
              resp.betEnable = false
            }
            return resp
          })
          .sort((a, b) => {
            if (a.betEnable && !b.betEnable) {
              return -1
            }
            if (!a.betEnable && b.betEnable) {
              return 1
            }
            return 0
          })
      }
      return {
        component: 'LottoList',
        props: listLottoProps
      }
    })
  const messages =
    announcements?.map((i) => {
      const title = i18n.language === 'th' ? i.title_th : i.title_en
      const content = i18n.language === 'th' ? i.content_th : i.content_en
      return (
        <span key={JSON.stringify(i)}>
          {title}
          <label style={{ marginLeft: '10px' }}>{content}</label>
        </span>
      )
    }) ?? []
  const homePageData: GenericWidgetProps = {
    component: 'Layout',
    props: {
      loading
    },
    items: [
      {
        component: 'Marquee',
        props: {
          speed: '15s',
          children: messages
        }
      },
      {
        component: 'SwiperComponent',
        props: swiperProps
      },
      {
        component: 'LottoFilter',
        props: {
          selected: [...selectFilter],
          menus,
          onSelect: onSetFilter,
          onClear
        }
      },
      ...genericWidgets
    ]
  }
  return { homePageData }
}
