import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

import { Autoplay, Pagination, Mousewheel, Keyboard } from 'swiper/modules'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

const StyleSwiper = styled(Swiper)<{ ispointer?: string }>`
  cursor: ${({ ispointer }) => `${ispointer}`}};
  .swiper-pagination {
    position: absolute;
    top: 30px;
    right: 20px;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    width: auto;
    height: 25px;
  }
`
export interface SwiperComponentProps {
  handleclick?: () => void
  items: React.ReactNode[]
}
export const SwiperComponent = ({
  items,
  handleclick
}: SwiperComponentProps) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    // Set the component to "mounted" after the first render
    setIsMounted(true)
  }, [])

  if (!isMounted || items.length === 0) {
    return null
  }
  if (items.length === 0) {
    return <></>
  }
  const ispointer = handleclick !== undefined ? 'pointer' : 'default'
  return (
    <StyleSwiper
      cssMode={true}
      mousewheel={true}
      keyboard={true}
      centeredSlides={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false
      }}
      modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
      slidesPerView={'auto'}
      spaceBetween={30}
      pagination={{
        clickable: true
      }}
      className="carousel slide container mt-3"
      style={{ maxHeight: 500, overflow: 'hidden' }}
      onClick={() => handleclick && handleclick()}
      ispointer={ispointer}
    >
      {items.map((i, idx) => (
        <SwiperSlide key={idx} style={{ height: '480px', overflowY: 'hidden' }}>
          {i}
        </SwiperSlide>
      ))}
    </StyleSwiper>
  )
}

export default SwiperComponent
