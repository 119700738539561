import styled from 'styled-components'

export interface LottoContainerProps {
  title?: string
  titleClass?: string
  buttonText?: string
  children?: React.ReactNode
  isShowButton?: boolean
  onClickButton?: () => void
}
const ButtonText = styled.button`
  width: fit-content;
  margin: 10px;
  background-color: #f1b625;
  color: #fff; /* Initial text color is white */
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  border: none;

  &:hover {
    background-color: #e2b551;
    color: #000;
  }
`

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`

const Title = styled.h2`
  font-size: 1.5rem;
  color: #333;
`

const LottoContainer = ({
  title,
  titleClass,
  isShowButton,
  buttonText,
  onClickButton,
  children
}: LottoContainerProps) => {
  return (
    <section className="lotto-list-section-container container">
      <div className="row">
        <ContainerHeader>
          <Title className={titleClass}>{title}</Title>
          {isShowButton && (
            <ButtonText type="button" onClick={onClickButton}>
              {buttonText}
            </ButtonText>
          )}
        </ContainerHeader>
        {children}
      </div>
    </section>
  )
}

export default LottoContainer
