import styled from 'styled-components'
import { useCountDown } from 'utils/date-time-utils'

export interface CountdownClockProps {
  title: string
  dueDate: string
  titleClass?: string
  clockClass?: string
}

const CountdownClockContainer = styled.div`
  background-color: rgb(60, 60, 60);
  color: #fff;
  padding: 20px;
  text-align: center;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 0;
  margin-bottom: 20px;

  h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #f1b625;
  }

  p {
    font-size: 24px;
    margin: 0;
  }
`

const KCountdownClock = ({
  title,
  dueDate,
  clockClass,
  titleClass
}: CountdownClockProps) => {
  const [h, m, s, loading] = useCountDown(dueDate)

  return (
    <CountdownClockContainer className={`text-center ${clockClass}`}>
      <h3 className={`text-center ${titleClass}`}>{title}</h3>
      <p className={`text-center`}>
        {loading ? (
          <>Loading...</>
        ) : (
          <span>
            {h} : {m} : {s}
          </span>
        )}
      </p>
    </CountdownClockContainer>
  )
}

export default KCountdownClock
