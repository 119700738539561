import {
  LimitTemplateBasePrice,
  LimitTemplateSpecialPrice,
  ResponseProcessLimit
} from 'redux/saga/limitHandlerSaga'
import { BetLottoType } from './lotto-context'

export const isInRange = (
  currentPrice: number,
  minPrice?: number | null,
  maxPrice?: number | null
): boolean => {
  if (minPrice !== undefined && minPrice !== null && currentPrice < minPrice) {
    return false
  }
  if (maxPrice !== undefined && maxPrice !== null && currentPrice > maxPrice) {
    return false
  }
  return true
}

// Helper function to check for special limits
const checkSpecialLimits = (
  number: string,
  betType: BetLottoType,
  totalBet: number,
  specialLimits: LimitTemplateSpecialPrice[]
): { limit: 'none' | 'limit' | 'range'; appliedRate: number } => {
  for (const specialLimit of specialLimits) {
    if (
      specialLimit.bet_number === number &&
      specialLimit.bet_type === betType &&
      specialLimit.type === 'limit' &&
      specialLimit.limit_price != null &&
      totalBet > specialLimit.limit_price
    ) {
      return { limit: 'limit', appliedRate: 0 }
    }
  }
  return { limit: 'none', appliedRate: 0 }
}

// Helper function to check for special ranges
const checkSpecialRanges = (
  number: string,
  betType: BetLottoType,
  totalBet: number,
  specialRate: number,
  specialLimits: LimitTemplateSpecialPrice[]
): { limit: 'none' | 'limit' | 'range'; appliedRate: number } => {
  let latestValidRate = specialRate
  let limit: 'none' | 'limit' | 'range' = 'none'

  for (const specialLimit of specialLimits) {
    if (
      specialLimit.bet_number === number &&
      specialLimit.bet_type === betType &&
      specialLimit.type === 'range'
    ) {
      const min = specialLimit.bet_price_min ?? -Infinity
      const max = specialLimit.bet_price_max ?? Infinity
      const rewardPercent = specialLimit.reward_percent ?? 100

      if (isInRange(totalBet, min, max)) {
        console.log('in special range')
        latestValidRate = specialRate * (rewardPercent / 100)
        limit = 'range' // Mark as range hit
      }
    }
  }

  return { limit, appliedRate: latestValidRate }
}

// Helper function to check for base limits
const checkBaseLimits = (
  totalBet: number,
  baseLimits: LimitTemplateBasePrice[],
  betType: BetLottoType
): { limit: 'none' | 'limit' | 'range'; appliedRate: number } => {
  if (!baseLimits) {
    return { limit: 'none', appliedRate: 0 }
  }
  for (const baseLimit of baseLimits) {
    if (
      baseLimit.bet_type === betType &&
      baseLimit.type === 'limit' &&
      baseLimit.limit_price != null &&
      totalBet > baseLimit.limit_price
    ) {
      return { limit: 'limit', appliedRate: 0 }
    }
  }
  return { limit: 'none', appliedRate: 0 }
}

// Helper function to check for base ranges with gap handling and correct rate application
const checkBaseRanges = (
  totalBet: number,
  baseRate: number,
  baseLimits: LimitTemplateBasePrice[],
  betType: BetLottoType
): { limit: 'none' | 'limit' | 'range'; appliedRate: number } => {
  let latestValidRate = baseRate
  let limit: 'none' | 'limit' | 'range' = 'none'

  for (const baseLimit of baseLimits) {
    if (baseLimit.type === 'range' && baseLimit.bet_type === betType) {
      const min = baseLimit.bet_price_min ?? -Infinity
      const max = baseLimit.bet_price_max ?? Infinity
      const payRatePercent = baseLimit.reward_percent ?? 100

      if (isInRange(totalBet, min, max)) {
        latestValidRate = baseRate * (payRatePercent / 100)
        limit = 'range'
      }
    }
  }

  return { limit, appliedRate: latestValidRate }
}

export const getLimitTypeWithRateUsingTemplate = (
  number: string,
  betType: BetLottoType,
  baseRate: number,
  betPrice: number,
  limitTemplate?: ResponseProcessLimit
): { limit: 'none' | 'limit' | 'range'; appliedRate: number } => {
  if (!limitTemplate) {
    return { limit: 'none', appliedRate: baseRate }
  }

  const {
    current_price,
    base_limit_price = [],
    special_limit_price = []
  } = limitTemplate
  const currentBet =
    current_price.current_bet[betType]?.[number]?.current_bet || 0
  const totalBet = currentBet + betPrice

  // Check if this number has any special rules
  const hasSpecialRules = special_limit_price?.some(
    (rule) => rule.bet_number === number && rule.bet_type === betType
  )

  // If this number has special rules, ONLY use special rules
  if (hasSpecialRules) {
    // Check special limits first
    for (const specialLimit of special_limit_price) {
      if (
        specialLimit.bet_number === number &&
        specialLimit.bet_type === betType
      ) {
        // Check limit type first
        if (
          specialLimit.type === 'limit' &&
          specialLimit.limit_price != null &&
          totalBet > specialLimit.limit_price
        ) {
          return { limit: 'limit', appliedRate: 0 }
        }
        // Then check range type
        if (specialLimit.type === 'range') {
          const min = specialLimit.bet_price_min ?? -Infinity
          const max = specialLimit.bet_price_max ?? Infinity
          const rewardPercent = specialLimit.reward_percent ?? 100

          if (isInRange(totalBet, min, max)) {
            return {
              limit: 'range',
              appliedRate: baseRate * (rewardPercent / 100)
            }
          }
        }
      }
    }
    // If we have special rules but none matched
    return { limit: 'none', appliedRate: baseRate }
  }

  // If no special rules exist for this number, use base rules
  if (!base_limit_price) {
    return { limit: 'none', appliedRate: baseRate }
  }

  // Check base limits first
  for (const baseLimit of base_limit_price) {
    if (
      baseLimit.bet_type === betType &&
      baseLimit.type === 'limit' &&
      baseLimit.limit_price != null &&
      totalBet > baseLimit.limit_price
    ) {
      return { limit: 'limit', appliedRate: 0 }
    }
  }

  // Then check base ranges
  let latestValidRate = baseRate
  let hasRange = false

  for (const baseLimit of base_limit_price) {
    if (baseLimit.bet_type === betType && baseLimit.type === 'range') {
      const min = baseLimit.bet_price_min ?? -Infinity
      const max = baseLimit.bet_price_max ?? Infinity
      const payRatePercent = baseLimit.reward_percent ?? 100

      if (isInRange(totalBet, min, max)) {
        latestValidRate = baseRate * (payRatePercent / 100)
        hasRange = true
      }
    }
  }

  if (hasRange) {
    return { limit: 'range', appliedRate: latestValidRate }
  }

  return { limit: 'none', appliedRate: baseRate }
}
