import LottoBetTabLayout from 'components/Layout/LottoBetTabLayout'
import KTwoNumberFunctionPanel, {
  KTwoNumberFunctionPanelProps
} from 'components/@kapook/TwoNumberFunctionPanel/KTwoNumberFunctionPanel'
import { useTwoNumber } from 'pages/lotto/hook/useTwoNumber'
import { useTranslation } from 'react-i18next'
import DisabledPanel from './diabled-panal'
import { MutatedInput, PanelButton } from './three-digit-panel'
import KNumberGrid from './@kapook/KNumberGrid'
const TwoDigitPanel = () => {
  const { t } = useTranslation()
  const {
    searchDigit,
    onSearchDigit,
    swapNumber,
    onToggleSwapNumber,
    numbers,
    menuSelected,
    onToggleMenu,
    onClickNumber,
    nineTeenSelect,
    startNumberSelect,
    endNumberSelect,
    onSetTwoDigitFunction,
    getLimitTypeUI,
    betTwo
  } = useTwoNumber()
  const TWO_DIGIT_FUNCTION: KTwoNumberFunctionPanelProps[] = [
    {
      title: t('betDetailPanel.betLottoType.start_number'),
      type: 'start_number',
      value: startNumberSelect
    },
    {
      title: t('betDetailPanel.betLottoType.end_number'),
      type: 'end_number',
      value: endNumberSelect
    },
    {
      title: t('betDetailPanel.betLottoType.19_number'),
      type: '19_number',
      value: nineTeenSelect
    }
  ]
  const renderPanel = () => {
    const renderElement: React.ReactNode[] = []
    Object.keys(betTwo).forEach((key) => {
      renderElement.push(
        <div className="col-12 col-lg-6" key={key}>
          <div
            className={`input-group mb-3 search-lottobox ${
              menuSelected.includes(key) && 'active'
            }`}
            onClick={() => onToggleMenu(key)}
          >
            <MutatedInput
              autoFocus={false}
              readOnly
              type="text"
              className="form-control"
              aria-describedby="inputGroup-sizing-default"
              placeholder={t(`betDetailPanel.betLottoType.${key}`).toString()}
            />
            <span className="input-group-text" id="inputGroup-sizing-default">
              {betTwo[key]}
            </span>
          </div>
        </div>
      )
    })
    return renderElement
  }
  return (
    <>
      <LottoBetTabLayout
        searchDigit={searchDigit}
        onSearchDigit={onSearchDigit}
        swapNumber={swapNumber}
        onToggleSwapNumber={onToggleSwapNumber}
      />

      <div className="row mb-2 lotto-win-rate-container">{renderPanel()}</div>
      <div className="select-lotto-number-container">
        {menuSelected.length == 0 && <DisabledPanel />}
        <div className="row select-1-number">
          {TWO_DIGIT_FUNCTION.map((item) => (
            <div className="col-lg-4 mt-2 mb-0 " key={JSON.stringify(item)}>
              <KTwoNumberFunctionPanel
                title={item.title}
                type={item.type}
                value={item.value}
                onSelectNumber={onSetTwoDigitFunction}
              />
            </div>
          ))}
        </div>
        <hr />
        <div className="row mb-2 selct-main-unit-number">
          <div className="col-12">
            <KNumberGrid
              numbers={numbers}
              onClickNumber={onClickNumber}
              getLimitTypeUI={getLimitTypeUI}
              menuSelected={menuSelected}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default TwoDigitPanel
