import LottoBetTabLayout from 'components/Layout/LottoBetTabLayout'
import { useRunningNumber } from 'pages/lotto/hook/useRunningNumber'
import { useTranslation } from 'react-i18next'
import DisabledPanel from './diabled-panal'
import { MutatedInput, PanelButton } from './three-digit-panel'
import KNumberGrid from './@kapook/KNumberGrid'
const RunningDigitPanel = () => {
  const {
    numbers,
    onClickNumber,
    menuSelected,
    onToggleMenu,
    searchDigit,
    onSearchDigit,
    getLimitTypeUI,
    betOne
  } = useRunningNumber()
  const { t } = useTranslation()
  const renderPanel = () => {
    const renderElement: React.ReactNode[] = []
    Object.keys(betOne)?.forEach((key) => {
      renderElement.push(
        <div className="col-12 col-lg-6" key={key}>
          <div
            className={`input-group mb-3 search-lottobox ${
              menuSelected.includes(key) && 'active'
            }`}
            onClick={() => onToggleMenu(key)}
          >
            <MutatedInput
              autoFocus={false}
              readOnly
              type="text"
              className="form-control"
              aria-describedby="inputGroup-sizing-default"
              placeholder={t(`betDetailPanel.betLottoType.${key}`).toString()}
            />
            <span className="input-group-text" id="inputGroup-sizing-default">
              {betOne[key]}
            </span>
          </div>
        </div>
      )
    })
    return renderElement
  }
  return (
    <>
      <LottoBetTabLayout
        searchDigit={searchDigit}
        onSearchDigit={onSearchDigit}
        hideSwap={true}
      />
      <div className="row mb-2 lotto-win-rate-container">{renderPanel()}</div>
      <div className="select-lotto-number-container">
        {menuSelected.length === 0 && <DisabledPanel />}
        <div className="row mb-2 selct-main-unit-number">
          <div className="col-12">
            <KNumberGrid
              menuSelected={menuSelected}
              numbers={numbers}
              onClickNumber={onClickNumber}
              getLimitTypeUI={getLimitTypeUI}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default RunningDigitPanel
