// import BetListItem from 'components/BetListItem'
import BetListItem from 'components/@kapook/BetListItem/KBetListItem'
import { LottoTypeGroup, useLottoContext } from 'contexts/lotto-context'
import { Svg } from 'images'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import numeral from 'numeral'
import { NUMBER_FORMAT } from 'utils/number-utils'
import { useParams } from 'react-router-dom'
import { isGroupLotto } from 'utils/lotto-type-utils'

export interface KBetDetailsPanelProps {
  listItem?: LottoTypeGroup[]
}

const ButtonYellow = styled.a`
  background-color: #f1b625;
  color: #333;
  padding: 15px 20px;
  text-align: center;
  border-radius: 8px;
  width: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  text-decoration: none;

  &:hover {
    background-color: #f7c248;
  }
`
const SilverButton = styled.a`
  background: linear-gradient(180deg, #e0e0e0 0%, #c0c0c0 100%);
  color: #333;
  padding: 12px 25px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-decoration: none;
  box-shadow: none;
  margin-left: auto;

  &:hover {
    background: linear-gradient(180deg, #c0c0c0 0%, #a0a0a0 100%);
  }

  i {
    margin-right: 8px;
    font-size: 24px;
  }

  svg {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
`

const BetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures even spacing between title and button */
  margin-bottom: 20px;
`

const BetTitle = styled.h5`
  font-size: 1.2rem;
  margin: 0;
`

const ButtonRed = styled.a`
  background-color: #ff4d4d;
  color: white;
  padding: 15px 20px;
  text-align: center;
  border-radius: 8px;
  width: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  text-decoration: none;

  &:hover {
    background-color: #e63946; /* Darker red on hover */
  }
`

const BetButton = styled.button`
  flex: 1;
  max-width: 100px;
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover {
    background-color: #f1b625;
    color: #333;
    border-color: #f1b625;
  }
`

const BetButtonContainer = styled.ul`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`

const ListItem = styled.li`
  cursor: pointer;
`

const BetPanelContainer = styled.div`
  padding: 15px;
  background-color: #fff;
  border-radius: 15px;
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`

const KBetDetailsPanel = (props: KBetDetailsPanelProps) => {
  const { t } = useTranslation()
  const { roundId } = useParams()
  const {
    game,
    betPrice,
    onManualBetPriceChange,
    betPriceChange,
    lottoGroupItem,
    deleteLottoList,
    onChangeSingleBetPrice,
    onClearBetList,
    onHandleSubmit,
    totalBetPrice,
    creditLeft
  } = useLottoContext()
  const items = (props?.listItem ?? lottoGroupItem).filter(
    (v) => v.items.length > 0
  )
  const onManualBetChange = (value: number) => {
    onManualBetPriceChange(value)
  }

  const renderItem = () => {
    if (!items || items?.length === 0) {
      return <EmptyList />
    }
    return (
      <BetListItem
        disable={isGroupLotto(game?.type ?? '')}
        listItem={items}
        onDeleteItem={deleteLottoList}
        onChangeSingleBetPrice={onChangeSingleBetPrice}
      />
    )
  }
  const pTotalBetPrice = isNaN(totalBetPrice) ? 0 : totalBetPrice
  const pCreditLeft = isNaN(creditLeft) ? 0 : creditLeft

  return (
    <BetPanelContainer className="mb-4">
      <BetHeader>
        <BetTitle>{t('betDetailPanel.listBet')}</BetTitle>
        <SilverButton
          title={t('betDetailPanel.listNumber').toString()}
          href="/lotto/poll"
        >
          <i className="dollar-coin"></i>
          {t('betDetailPanel.listNumber')}
        </SilverButton>
      </BetHeader>
      <hr />
      <div className="mb-4" style={{ maxHeight: '500px', overflowX: 'auto' }}>
        {renderItem()}
      </div>
      <div className="mb-4">
        {!isGroupLotto(game?.type ?? '') && (
          <>
            <div className="input-group input-group-lg mb-3">
              <span className="input-group-text">
                <img src={Svg.iconDollarCoin} alt="" />
              </span>
              <input
                title="user-bet-total"
                type="number"
                value={betPrice ?? ''}
                onChange={(e) => onManualBetChange(e.target.valueAsNumber)}
                className="form-control"
              />
            </div>
            <BetButtonContainer>
              {[10, 20, 50, 100].map((v) => (
                <ListItem className="list-inline-item" key={v}>
                  <BetButton onClick={() => betPriceChange(v)}>{v}฿</BetButton>
                </ListItem>
              ))}
            </BetButtonContainer>
          </>
        )}
        <p>
          <strong>{t('betDetailPanel.creditAmount')}</strong>{' '}
          <span style={{ color: '#f1b625' }}>
            {numeral(pCreditLeft).format(NUMBER_FORMAT)}
          </span>{' '}
          ฿
        </p>
        <p>
          <strong>{t('betDetailPanel.betAmount')}</strong>{' '}
          <span style={{ color: '#f1b625' }}>
            {numeral(pTotalBetPrice).format(NUMBER_FORMAT)}
          </span>{' '}
          ฿
        </p>
      </div>
      <ButtonContainer>
        <ButtonRed onClick={onClearBetList} title="clear">
          {t('betDetailPanel.clear')}
        </ButtonRed>
        <ButtonYellow
          onClick={() => onHandleSubmit(parseInt(roundId ?? '-1'))}
          title={t('betDetailPanel.bet').toString()}
        >
          {t('betDetailPanel.bet')}
        </ButtonYellow>
      </ButtonContainer>
    </BetPanelContainer>
  )
}

const EmptyList = () => {
  const { t } = useTranslation()
  return (
    <p className="text-center pt-4 pb-4">- {t('betDetailPanel.emptyList')} -</p>
  )
}

export default KBetDetailsPanel
