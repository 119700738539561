import { LayoutProps } from 'components/Layout/Layout'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import React from 'react'

type FooterProps = LayoutProps

const Footer = ({ hideMenu }: FooterProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const isActive = (section: 'home' | 'poll' | 'result') => {
    const path = location.pathname

    if (
      section === 'home' &&
      !path.includes('/poll') &&
      !path.includes('/result')
    ) {
      return true
    }

    if (section === 'poll' && path.includes('/poll')) {
      return true
    }

    if (section === 'result' && path.includes('/result')) {
      return true
    }

    return false
  }

  return (
    <footer className="footer-white-container sticky-bottom">
      {!hideMenu && (
        <div className="container">
          <div className="row footer-icon-list">
            <div className="col-12 col-md-12 col-lg-8 offset-lg-2">
              <div className="row">
                <div className="col-4 col-md-4 col-lg-4 footer-icon-list-items">
                  <a
                    onClick={() => navigate('/lotto')}
                    title={t('footer.home') ?? 'หน้าแรก'}
                    className={`text-decoration-none ${
                      isActive('home') ? 'active' : ''
                    }`}
                  >
                    <div className="footer-icon-list-items-wrapper d-flex flex-column align-items-center justify-content-center">
                      <i className="footer-icon" id="footer-icon-home"></i>
                      <span className="footer-list-items-title">
                        {t('footer.home')}
                      </span>
                    </div>
                  </a>
                </div>

                <div className="col-4 col-md-4 col-lg-4">
                  <a
                    onClick={() => navigate('/lotto/poll')}
                    title="poll"
                    className={`text-decoration-none ${
                      isActive('poll') ? 'active' : ''
                    }`}
                  >
                    <div className="footer-icon-list-items-wrapper d-flex flex-column align-items-center justify-content-center">
                      <i className="footer-icon" id="footer-icon-bet-list"></i>
                      <span className="footer-list-items-title">
                        {t('footer.report')}
                      </span>
                    </div>
                  </a>
                </div>

                <div className="col-4 col-md-4 col-lg-4">
                  <a
                    onClick={() => navigate('/lotto/result')}
                    title={t('footer.resulted') ?? 'ผลวิเคราะห์'}
                    className={`text-decoration-none ${
                      isActive('result') ? 'active' : ''
                    }`}
                  >
                    <div className="footer-icon-list-items-wrapper d-flex flex-column align-items-center justify-content-center">
                      <i className="footer-icon" id="footer-icon-analysis"></i>
                      <span className="footer-list-items-title">
                        {t('footer.resulted')}
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </footer>
  )
}

export default Footer
