import KNumberInput from 'components/@kapook/NumberInput/KNumberInput'
import { useTranslation } from 'react-i18next'
import { useShootNumber } from './hook/useShootNumber'
import YeeKeeTable from './yeekee/yeekee-table'
import styled from 'styled-components'
interface ShootNumberTabProps {
  dueDate: string
}
const SummaryPutNumber = styled.h4`
  text-align: center;
`
const WaitingText = styled.p`
  text-align: center;
  color: red;
`
export const ShootNumberTab = ({ dueDate }: ShootNumberTabProps) => {
  const { t } = useTranslation()
  const {
    data,
    limit,
    onLimitChange,
    onPageChange,
    page,
    columns,
    addDigit,
    currentNumber,
    deleteNumber,
    countDown,
    summary
  } = useShootNumber(true)
  return (
    <div className="col-12 col-lg 12">
      <SummaryPutNumber>{t('betResult.summary_put_number')}</SummaryPutNumber>
      {countDown && (
        <WaitingText>
          {t('message.waitingForPutAgain', { second: countDown })}
        </WaitingText>
      )}
      <center>
        <h3>{summary}</h3>
      </center>
      <KNumberInput
        maxDigit={5}
        loading={countDown != undefined}
        onAddNumber={addDigit}
        currentNumber={currentNumber}
        onDeleteNumber={deleteNumber}
      />
      <YeeKeeTable
        columns={columns}
        limit={limit}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        page={page}
        data={data?.data ?? []}
        count={data.count}
      />
    </div>
  )
}
