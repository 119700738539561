import Banner from 'components/Banner'
import Layout, { LayoutProps } from 'components/Layout/Layout'
import LottoList, { LottoListProps } from 'components/LottoList'
import SearchBar from 'components/SearchBar'
import LoadMore, { LoadMoreProps } from 'components/Loadmore'
import Loading from 'components/Loading'
import BetListItem from 'components/BetListItem'
import TablePagination, {
  GenericTable,
  TablePaginationProps,
  TableProps
} from 'components/TablePagination'
import { FixTypeLater } from 'react-redux'
import ModalComponent from 'components/@kapook/Modal/KModalComponent'
import { /**ModalComponent,**/ ModalComponentProps } from 'components/Modal'
import Component404, { Component404Props } from 'components/404Component'
import Contact from 'components/Contact'
import { /**LottoFilter,**/ LottoFilterProps } from 'components/LottoFilter'
import LottoFilter from 'components/@kapook/LottoFilter/KLottoFilter'

import { /**Marquee**/ DynamicMarqueeProps } from 'components/Marquee'
import Marquee from 'components/@kapook/DynamicMarquee/KDynamicMarquee'
import SwiperComponent, { SwiperComponentProps } from 'components/Swiper'
import LayoutInfo, { LayoutInfoProps } from 'components/Layout/LayoutInfo'
export type GenericWidgetProps =
  | {
      component: 'Banner'
      props?: never
    }
  | {
      component: 'Layout'
      props?: LayoutProps
      items: GenericWidgetProps[]
    }
  | {
      component: 'LayoutInfo'
      props?: LayoutInfoProps
      items: GenericWidgetProps[]
    }
  | {
      component: 'SearchBar'
      props?: never
    }
  | {
      component: 'LottoList'
      props: LottoListProps
    }
  | {
      component: 'LoadMore'
      props: LoadMoreProps
    }
  | {
      component: 'BetListItem'
      props?: never
    }
  | {
      component: 'TablePagination'
      props: TablePaginationProps
    }
  | {
      component: 'GenericTable'
      props: TableProps & FixTypeLater
    }
  | {
      component: 'ModalComponent'
      props: ModalComponentProps
    }
  | {
      component: 'Component404'
      props?: Component404Props
    }
  | {
      component: 'Loading'
      props?: never
    }
  | {
      component: 'Contact'
      props?: never
    }
  | {
      component: 'LottoFilter'
      props: LottoFilterProps
    }
  | {
      component: 'Marquee'
      props: DynamicMarqueeProps
    }
  | {
      component: 'SwiperComponent'
      props: SwiperComponentProps
    }

const GenericWidget = (props: GenericWidgetProps) => {
  const components = {
    Banner,
    Layout,
    SearchBar,
    LottoList,
    LoadMore,
    BetListItem,
    GenericTable,
    TablePagination,
    ModalComponent,
    Component404,
    Loading,
    Contact,
    LottoFilter,
    Marquee,
    SwiperComponent,
    LayoutInfo
  }

  const renderLayout = (items: GenericWidgetProps[], props?: LayoutProps) => {
    return (
      <Layout {...props}>
        {items?.map((i, idx) => <GenericWidget key={idx} {...i} />)}
      </Layout>
    )
  }
  const renderLayoutInfo = (
    items: GenericWidgetProps[],
    props?: LayoutInfoProps
  ) => {
    return (
      <LayoutInfo {...props}>
        {items?.map((i, idx) => <GenericWidget key={idx} {...i} />)}
      </LayoutInfo>
    )
  }
  const renderWidget = () => {
    if (props.component == 'Layout') {
      return renderLayout(props.items, props.props)
    } else if (props.component === 'LayoutInfo') {
      return renderLayoutInfo(props.items, props.props)
    } else {
      const Component = components[props.component]
      return <Component {...props.props} />
    }
  }
  return renderWidget()
}

export default GenericWidget
