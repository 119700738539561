import styled from 'styled-components'

export type NavItem = {
  key: string
  name: string
  icon?: string | React.ReactNode
  itemClass?: string
  iconActive?: React.ReactNode
  iconInactive?: React.ReactNode
}

export interface KNavbarBetPageProps {
  selected?: string
  onChangeItem: (key: any) => void
  navBarClass?: string
  items: NavItem[]
}

export interface NavBarItemProps {
  name: string
  active: boolean
  onChangeItem?: () => void
  icon?: string | React.ReactNode
  itemClass?: string
  iconActive?: React.ReactNode
  iconInactive?: React.ReactNode
}

const NavbarContainer = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  gap: 0;
  width: 100%;
`

const NavbarItemContainer = styled.li<{
  active: boolean
  isFirst: boolean
  isLast: boolean
}>`
  flex: 1;
  background-color: ${(props) => (props.active ? '#f1b625' : '#c0c0c0')};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: background-color 0.3s ease;
  cursor: pointer;
  padding: 10px 0;
  border-radius: ${(props) =>
    props.isFirst ? '15px 0 0 0' : props.isLast ? '0 15px 0 0' : '0'};
`

const NavbarButton = styled.button<{ active: boolean }>`
  background: none;
  border: none;
  color: ${(props) => (props.active ? '#000' : '#fff')};
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &:hover {
    color: #000; /* Change the text color to black when hovering */
  }

  i {
    margin-bottom: 8px;
  }

  svg {
    margin-bottom: 8px;
    width: 32px;
    height: auto;
  }
`

export const KNavBarItem = ({
  active,
  onChangeItem,
  icon,
  name,
  iconActive,
  iconInactive,
  isFirst,
  isLast
}: NavBarItemProps & { isFirst: boolean; isLast: boolean }) => (
  <NavbarItemContainer
    active={active}
    onClick={onChangeItem}
    isFirst={isFirst}
    isLast={isLast}
  >
    <NavbarButton active={active}>
      {active ? iconActive : iconInactive}
      <span>{name}</span>
    </NavbarButton>
  </NavbarItemContainer>
)

const KNavbarBetPage = ({
  selected,
  onChangeItem,
  navBarClass,
  items
}: KNavbarBetPageProps) => {
  return (
    <NavbarContainer className={navBarClass}>
      {items.map((i, index) => (
        <KNavBarItem
          name={i.name}
          key={i.key}
          active={i.key === selected}
          icon={i.icon}
          iconActive={i.iconActive}
          iconInactive={i.iconInactive}
          onChangeItem={() => onChangeItem(i.key)}
          isFirst={index === 0}
          isLast={index === items.length - 1}
        />
      ))}
    </NavbarContainer>
  )
}

export default KNavbarBetPage
