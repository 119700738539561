import { SwiperComponentProps } from 'components/Swiper'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/reducers'
import {
  isEncryptLotto,
  isGroupLotto,
  isYeekeeType
} from 'utils/lotto-type-utils'
import { GetResultProps } from './useResulted'
import { useTranslation } from 'react-i18next'
import Resulted from '../resulted'
import ResultTable from 'components/@kapook/ResultTable/KResultTable'
import { useNavigate } from 'react-router-dom'
import { useIsSmallScreen } from 'hooks/useIsSamllScreen'

export const useResultSlider = () => {
  const isSmallScreen = useIsSmallScreen(425)
  const navigation = useNavigate()
  const { i18n, t } = useTranslation()
  const { results } = useSelector((state: RootState) => ({
    results: state.games.results
  }))
  const handleClick = () => {
    navigation('/lotto/result')
  }
  const swiperProps: SwiperComponentProps = {
    items: [],
    handleclick: handleClick
  }
  results?.forEach((i) => {
    if (i.rounds.length > 0) {
      if (
        !isYeekeeType(i.type) &&
        !isEncryptLotto(i.type) &&
        i.rounds.length > 0
      ) {
        const props = GetResultProps(i18n, t, i.rounds?.[0], i)
        swiperProps.items.push(<Resulted key={i.id} {...props} swiper />)
      }
      if (
        !isYeekeeType(i.type) &&
        isGroupLotto(i.type) &&
        !isEncryptLotto(i.type)
      ) {
        const props = GetResultProps(i18n, t, undefined, i)
        swiperProps.items.push(
          <Resulted key={i.id} {...props} noRound={i.rounds.length === 0} />
        )
      }
      if (
        isYeekeeType(i.type) ||
        (isEncryptLotto(i.type) && i.rounds.length > 0)
      ) {
        const props = GetResultProps(
          i18n,
          t,
          undefined,
          i,
          navigation,
          false,
          isSmallScreen
        )
        const lastSixRounds = i.rounds.slice(-6)
        if (lastSixRounds.length > 0) {
          lastSixRounds.pop()
        }
        swiperProps.items.push(
          <ResultTable key={i.id} {...props} data={lastSixRounds} swiper />
        )
      }
    }
  })
  return { swiperProps }
}
