import { navItem } from 'components/Navbar'
import { useNavBar } from 'components/Navbar/useNavbar'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'redux/reducers'
import {
  FetchGameRoundPayload,
  PayloadFetchGame
} from 'redux/saga/fetchGameListSaga'
import { sagaActions } from 'redux/sagaActions'
import { LottoNavType } from 'types/navbar'
import dayjs, {
  DATE_TIME_FORMAT_HUMAN,
  convertToDateTime
} from 'utils/date-time-utils'
import GroupLotto from '../group-lotto'
import { ShootNumberTab } from '../shoot-number'
import BetLottoPage from '../bet-lotto'
import Resulted from '../resulted'
import {
  getRuleData,
  isEncryptLotto,
  isGroupLotto,
  isYeekeeType
} from 'utils/lotto-type-utils'
import useResulted from './useResulted'
import { createSelector } from '@reduxjs/toolkit'
import { KapookSvg } from 'images'
// Selecting game data
const selectGame = createSelector(
  [(state: RootState) => state.games.game],
  (game) => game
)

const selectRound = createSelector(
  [(state: RootState) => state.games.round],
  (round) => round
)

const selectLoading = createSelector(
  [(state: RootState) => state.games.loading],
  (loading) => loading.game || loading.round
)

const selectError = createSelector(
  [(state: RootState) => state.games.error],
  (error) => error
)

const selectLimit = createSelector(
  [(state: RootState) => state.limitTemplate],
  (error) => error
)

const lottoBetPageSelector = createSelector(
  [selectGame, selectRound, selectLoading, selectError, selectLimit],
  (game, round, loading, limitTemplate, error) => ({
    game,
    round,
    loading,
    error,
    limitTemplate
  })
)
const LottoBetPage = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const { gameType, gameId, roundId } = useParams()
  useEffect(() => {
    const roundPayload: FetchGameRoundPayload = {
      roundId: parseInt(roundId ?? '')
    }
    const gamePayload: PayloadFetchGame = {
      gameId: parseInt(gameId ?? '')
    }
    dispatch({
      type: sagaActions.FETCH_LIMIT_ROUND_NUMBERS,
      payload: roundPayload
    })
    dispatch({ type: sagaActions.FETCH_GAME_ROUND, payload: roundPayload })
    dispatch({ type: sagaActions.FETCH_GAME, payload: gamePayload })
  }, [])
  const { game, round, loading } = useSelector((state: RootState) =>
    lottoBetPageSelector(state)
  )

  const resultProps = useResulted(isEncryptLotto(game?.type ?? ''))
  const betRuleData = getRuleData(round)
  const [isRoundClosed, setIsRoundClosed] = useState<boolean | undefined>()
  const shootTime = dayjs(round?.close_at).add(
    round?.yeekee_config?.put_number_extend_time ?? 60,
    'second'
  )
  const shootTimeString = shootTime
    .utcOffset(0)
    .format('YYYY-MM-DDTHH:mm:ss[Z]')
  const [isYeekeeResulted, setIsYeekeeResulted] = useState<
    boolean | undefined
  >()

  const updateRoundClosed = () => {
    const roundClosed =
      dayjs(round?.close_at).isBefore(dayjs()) ||
      round?.is_enable === 'no' ||
      round?.is_result_confirmed

    setIsRoundClosed(roundClosed)
  }

  const updateYeekeeResulted = () => {
    const yeekeeResulted =
      shootTime.isBefore(dayjs()) ||
      round?.is_enable === 'no' ||
      (round?.is_result_confirmed && isYeekeeType(game?.type ?? ''))
    setIsYeekeeResulted(yeekeeResulted)
  }

  const isYeekee = isYeekeeType(game?.type ?? '')

  useEffect(() => {
    const intervalRoundClose = setInterval(() => {
      if (!isRoundClosed) {
        updateRoundClosed()
      }
    }, 1000)

    const intervalYeekeeResulted = setInterval(() => {
      if (isYeekee && !isYeekeeResulted) {
        updateYeekeeResulted()
      }
    }, 1000)
    return () => {
      clearInterval(intervalRoundClose)
      clearInterval(intervalYeekeeResulted)
    }
  }, [round, game, isRoundClosed, isYeekee, isYeekeeResulted])
  const getDetails = () => {
    const closeAt = round?.close_at
      ? convertToDateTime(
          round.close_at,
          DATE_TIME_FORMAT_HUMAN,
          localStorage.getItem('lang') ?? i18n.language
        )
      : '-'
    const detailPrefix = t('lottoPage.status.close')
    return `${detailPrefix} ${closeAt}`
  }
  const getTitlePage = () => {
    if (isYeekee || isEncryptLotto(game?.type ?? '')) {
      return t('lottoPage.lottoRound.round_name', {
        name:
          localStorage.getItem('lang') === 'th' ? game?.name_th : game?.name_en,
        round: round?.round_number
      })
    }
    return localStorage.getItem('lang') === 'th' ? game?.name_th : game?.name_en
  }
  const { selected, setSelectedItem } = useNavBar<LottoNavType>(
    'pills-bet-lotto-tab'
  )
  let lottoNaveItem: navItem[] = [
    {
      name: t('lottoPage.navbar.lotto'),
      key: 'pills-bet-lotto-tab',
      // icon: 'icon-bullet icon-lotto',
      iconActive: <KapookSvg.kBetIconActive />,
      iconInactive: <KapookSvg.kBetIcon />
    },
    {
      name: t('lottoPage.navbar.shootNumber'),
      key: 'pills-shoot-lotto-number-tab',
      // icon: 'icon-bullet icon-shoot-lotto'
      iconActive: <KapookSvg.kShootIconActive />,
      iconInactive: <KapookSvg.kShootIcon />
    },
    {
      name: t('lottoPage.navbar.rule'),
      key: 'pills-bet-rules-tab',
      // icon: 'icon-bullet icon-lotto-rule'
      iconActive: <KapookSvg.kRuleIconActive />,
      iconInactive: <KapookSvg.kRuleIcon />
    }
  ]
  if (isRoundClosed && isYeekee) {
    lottoNaveItem = lottoNaveItem.filter((i) => i.key !== 'pills-bet-lotto-tab')
  }
  if (!['yeekee', 'yeekee_vip'].includes(game?.type ?? '')) {
    lottoNaveItem = lottoNaveItem.filter(
      (i) => i.key !== 'pills-shoot-lotto-number-tab'
    )
  }
  const renderSubPage = () => {
    if (isRoundClosed && !isYeekee && selected !== 'pills-bet-rules-tab') {
      return <Resulted {...resultProps} />
    }
    if (isGroupLotto(game?.type ?? '') && selected !== 'pills-bet-rules-tab') {
      return (
        <div className="main-tab-content-container">
          <GroupLotto />
        </div>
      )
    } else {
      switch (selected) {
        case 'pills-bet-rules-tab':
          return (
            <div className="main-tab-content-container">
              <div className="bet-card">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      (i18n.language === 'th'
                        ? game?.desc_th
                        : game?.desc_en) ?? ''
                  }}
                />
              </div>
            </div>
          )
        case 'pills-shoot-lotto-number-tab':
          return (
            <div className="main-tab-content-container">
              <ShootNumberTab dueDate={shootTimeString} />
            </div>
          )
        case 'pills-bet-lotto-tab':
          return <BetLottoPage signature={round?.signature} />
      }
    }
  }
  const onNextRound = () => {
    if (round?.next_id && round.next_id > 0) {
      window.location.href = `/lotto/${gameType}/${gameId}/${round?.next_id}`
    }
  }
  const onPrevRound = () => {
    if (round?.previous_id && round.previous_id > 0) {
      window.location.href = `/lotto/${gameType}/${gameId}/${round?.previous_id}`
    }
  }

  return {
    game,
    gameType: game?.type,
    round,
    getTitlePage,
    selected,
    loading:
      loading ||
      isRoundClosed === undefined ||
      (isYeekee && isYeekeeResulted === undefined),
    lottoNaveItem,
    renderSubPage,
    setSelectedItem,
    isRoundClosed,
    isYeekee,
    isYeekeeResulted,
    onNextRound,
    onPrevRound,
    betRuleData,
    details: getDetails(),
    shootTime: shootTimeString
  }
}
export default LottoBetPage
