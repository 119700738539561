import { useIsSmallScreen } from 'hooks/useIsSamllScreen'
import { compact } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export interface KResultCardProps {
  title?: string
  header?: string
  headerResult?: string
  detail?: string
  icon?: string
  results?: KResultItemProps[]
  noRound?: boolean
  onClickHeader?: () => void
  swiper?: boolean
}

export type KResultItemProps = {
  title?: string
  result?: string
  noRound?: boolean
  wrapper?: string
}

export interface KResultWrapperProps extends KResultCardProps {
  children: React.ReactNode
}

// Styled Components

const CardContainer = styled.div<{ swiper?: boolean; compact?: boolean }>`
  padding: 1rem;
  margin: 20px auto;
  height: ${(props) => (props.swiper ? '-webkit-fill-available' : 'auto')};
  max-height: 450px;
  background: rgb(248, 215, 138);
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: ${(props) => (props.swiper ? '0.875rem' : '1rem')};

  @media (max-width: 768px) {
    overflow-y: auto;
    padding: ${(props) => (props.compact ? '0.5rem' : '1rem')};
  }
`

const Header = styled.h3<{ compact?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: ${(props) => (props.compact ? '1.5rem' : '1.75rem')};
  font-weight: bold;
  color: #000;
  padding: ${(props) => (props.compact ? '0.25rem' : '0.5rem')};
  border-radius: 12px;

  @media (max-width: 768px) {
    font-size: ${(props) => (props.compact ? '1.25rem' : '1.5rem')};
  }
`

const MiniFlag = styled.img`
  width: 24px;
  height: 24px;
`

const Title = styled.span<{ clickable?: boolean }>`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  font-size: 1.5rem;
  color: #000;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`

const DateDetail = styled.span`
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`

const ResultGroupContainer = styled.div<{ compact?: boolean }>`
  background: #ffffff;
  border-radius: 12px;
  padding: ${(props) => (props.compact ? '1rem' : '1.5rem')};
  margin: ${(props) => (props.compact ? '1rem 0' : '1.5rem 0')};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid #d1d5db;
  overflow-x: auto;
  white-space: nowrap;
`

const ResultTitle = styled.div<{ swiper?: boolean; compact?: boolean }>`
  font-size: ${(props) =>
    props.compact ? '0.75rem' : props.swiper ? '0.875rem' : '1.5rem'};
  color: #000;
  margin-bottom: ${(props) => (props.compact ? '0' : '1rem')};
  font-weight: bold;
`

const ResultNumberContainer = styled.div<{ compact?: boolean }>`
  background: #ffffff;
  padding: ${(props) => (props.compact ? '0.5rem' : '1rem')};
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow-x: ${(props) => (props.compact ? 'hidden' : 'auto')};
  white-space: nowrap;
  flex-grow: 1;
`

const ResultNumber = styled.div<{ swiper?: boolean; compact?: boolean }>`
  background: #333;
  color: #fff;
  font-size: ${(props) =>
    props.compact
      ? 'clamp(0.875rem, 1.2vw, 1rem)' // Smaller font for compact mode
      : props.swiper
      ? 'clamp(1.25rem, 1.8vw, 1.5rem)' // Smaller font for swiper mode
      : 'clamp(1.5rem, 2.5vw, 1.75rem)'};
  padding: ${(props) => (props.compact ? '0.4rem 0.8rem' : '1rem 1.5rem')};
  border-radius: 12px;
  min-width: ${(props) => (props.compact ? '18px' : '40px')};
  display: inline-block;
  margin: ${(props) => (props.compact ? '0.1rem' : '0.5rem')};
  max-width: 50%;
`

const renderNumber = (
  number: string,
  swiper?: boolean,
  t?: (key: string) => string,
  compact?: boolean
) => {
  const cleanedNumber = number.replace(/\s+/g, '')
  if (!isNaN(Number(cleanedNumber))) {
    return cleanedNumber.split('').map((digit, index) => (
      <React.Fragment key={index}>
        <ResultNumber swiper={swiper} compact={compact}>
          {digit}
        </ResultNumber>
        {(index + 1) % 3 === 0 && index + 1 !== cleanedNumber.length && (
          <span style={{ margin: '0 0.5rem' }}></span>
        )}
      </React.Fragment>
    ))
  } else if (number !== '') {
    return (
      <ResultNumber swiper={swiper} compact={compact}>
        {number}
      </ResultNumber>
    )
  }

  return (
    <ResultNumber swiper={swiper} compact={compact}>
      {t && t('message.no_round')}
    </ResultNumber>
  )
}

export const KResultWrapper = ({
  title,
  icon,
  detail,
  header,
  headerResult,
  noRound,
  children,
  onClickHeader,
  swiper,
  compact
}: KResultWrapperProps & { compact?: boolean }) => {
  return (
    <CardContainer swiper={swiper} compact={compact} key={title}>
      <Header compact={compact}>
        {icon && <MiniFlag src={icon} alt={title} />}
        {title && (
          <Title clickable={!!onClickHeader} onClick={onClickHeader}>
            {title}
          </Title>
        )}
      </Header>
      {detail && <DateDetail>{detail}</DateDetail>}
      {children}
    </CardContainer>
  )
}

const KResultCard = ({
  title,
  detail,
  icon,
  results,
  header,
  headerResult,
  noRound,
  onClickHeader,
  swiper
}: KResultCardProps) => {
  const { t } = useTranslation()
  const isCompact = results && results.length > 3
  const isSmallScreen = useIsSmallScreen(325)
  return (
    <KResultWrapper
      title={title}
      detail={detail}
      icon={icon}
      onClickHeader={onClickHeader}
      noRound={noRound}
      swiper={swiper}
      compact={isCompact}
    >
      {header && (
        <ResultGroupContainer compact={isCompact}>
          <ResultTitle swiper={swiper} compact={isCompact}>
            {header}
          </ResultTitle>
          <ResultNumberContainer compact={isCompact}>
            {headerResult ? (
              renderNumber(headerResult, swiper, t, isCompact)
            ) : (
              <ResultNumber swiper={swiper} compact={isCompact}>
                {t('message.no_round')}
              </ResultNumber>
            )}
          </ResultNumberContainer>
        </ResultGroupContainer>
      )}
      {results && results.length > 0 && (
        <ResultNumberContainer compact={isCompact}>
          <div className="row">
            {results.map(
              (result, index) =>
                result.result && (
                  <div key={index} className={'col-12'}>
                    <ResultTitle swiper={swiper} compact={isCompact}>
                      {result.title}
                    </ResultTitle>
                    {!noRound && result.result ? (
                      <div>
                        {renderNumber(result.result, swiper, t, isCompact)}
                      </div>
                    ) : (
                      <ResultNumber swiper={swiper} compact={isCompact}>
                        {t('message.no_round')}
                      </ResultNumber>
                    )}
                  </div>
                )
            )}
          </div>
        </ResultNumberContainer>
      )}
    </KResultWrapper>
  )
}

export default KResultCard
