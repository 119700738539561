import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const LanguageSelector = () => {
  const { i18n } = useTranslation()
  const [selectedLang, setSelectedLang] = useState<string | null>(null)

  const changeLanguage = (lng: string) => {
    localStorage.setItem('lang', lng)
    setSelectedLang(lng)
    i18n.changeLanguage(lng)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        const storedLang = localStorage.getItem('lang')
        if (!storedLang) {
          localStorage.setItem('lang', 'th')
          setSelectedLang('th')
          i18n.changeLanguage('th')
        } else {
          setSelectedLang(storedLang)
          i18n.changeLanguage(storedLang)
        }
      } catch (error) {
        console.error('Error accessing localStorage:', error)
      }
    }
  }, [i18n])

  return (
    <div className="language-selector-container">
      <div className="language-selector">
        <select
          name="language"
          id="language"
          onChange={(e) => changeLanguage(e.target.value)}
          value={selectedLang ?? i18n.language}
          title="Language Selector"
          aria-label="Select Language"
        >
          <option value="en">En</option>
          <option value="th">Th</option>
        </select>
      </div>
    </div>
  )
}

export default LanguageSelector
