import styled from 'styled-components'
import React from 'react'
import { Column } from 'components/TablePagination'
import { FixTypeLater } from 'react-redux'
import { ResultTableProps } from 'components/ResultTable'
import { useTranslation } from 'react-i18next'

const ResultTableContainer = styled.div`
  background-color: #f8d78a;
  border-radius: 10px;
  padding: 20px;
  max-width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 450px;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const Title = styled.h3`
  margin: 0;
  font-size: 1.5rem;
`

const TableWrapper = styled.div<{ swiper?: boolean }>`
  width: 100%;
  max-height: 480px;
  overflow-y: ${(props) => (props.swiper ? 'hidden' : 'auto')};
  border-radius: 10px;
  padding: 10px;
  scrollbar-width: ${(props) => (props.swiper ? 'none' : 'thin')};
  scrollbar-color: #ffb347 #ffe4b5;

  &::-webkit-scrollbar {
    width: ${(props) => (props.swiper ? '0' : '10px')};
  }

  &::-webkit-scrollbar-track {
    background: #ffe4b5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffb347;
    border-radius: 10px;
    border: 3px solid #ffe4b5;
  }
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
`

const StyledThead = styled.thead`
  background-color: #f8d78a;
  color: #000;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 2;
`

const StyledTh = styled.th`
  padding: 15px;
  font-weight: bold;
  text-align: ${(props) => props.align || 'left'};
`

const StyledRow = styled.tr`
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const StyledCell = styled.td`
  padding: 20px;
  text-align: ${(props) => props.align || 'left'};
`

const KapookResultTable = ({
  title,
  icon,
  detail,
  columns,
  data,
  swiper
}: ResultTableProps) => {
  const { t } = useTranslation()

  const renderEmptyRow = (columns: Column[]) => (
    <StyledRow>
      <StyledCell colSpan={columns.length} align="center">
        {t('message.no_round')}
      </StyledCell>
    </StyledRow>
  )

  return (
    <ResultTableContainer>
      <TableWrapper swiper={swiper}>
        <StyledTable>
          <StyledThead>
            <tr>
              {columns.map((column, idx) =>
                idx === 0 ? (
                  <StyledTh key={`th-title-${idx}`}>
                    <TitleContainer>
                      {icon && (
                        <img src={icon} alt={title} style={{ width: '30px' }} />
                      )}
                      <Title>{title}</Title>
                      {detail && <span>{detail}</span>}
                    </TitleContainer>
                  </StyledTh>
                ) : (
                  <StyledTh key={column.accessor} style={column?.thStyle}>
                    {column.name}
                  </StyledTh>
                )
              )}
            </tr>
          </StyledThead>
          <tbody>
            {data?.length === 0 && renderEmptyRow(columns)}
            {data?.map((item: FixTypeLater, idx: number) => (
              <StyledRow key={`row-${idx}`}>
                {columns.map((column) => (
                  <StyledCell
                    key={`cell-${idx}-${column.accessor}`}
                    className={column?.tdClass ?? ''}
                    style={column?.tdStyle}
                  >
                    {column?.Cell?.(item, idx) ?? (
                      <>{item?.[column?.accessor]}</>
                    )}
                  </StyledCell>
                ))}
              </StyledRow>
            ))}
          </tbody>
        </StyledTable>
      </TableWrapper>
    </ResultTableContainer>
  )
}

export default KapookResultTable
