import React from 'react'
import styled from 'styled-components'

export interface KListPanelProps {
  numbers: Array<{
    parent: string
    active: boolean
  }>
  onToggleParent: (parent: string) => void
}

const ListContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  gap: 5px;
`

const ListItem = styled.li`
  flex-basis: calc(20% - 8px);
  max-width: calc(20% - 8px);
  margin: 4px 0;
`

const PanelButton = styled.button<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#f1b625' : '#ffffff')};
  color: ${(props) => (props.active ? '#000' : '#333')};
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 6px 10px;
  font-size: 14px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  width: 100%;
  text-align: center;

  &:hover {
    background-color: #f1b625;
    color: #000;
  }

  &.active {
    background-color: #f1b625;
    color: #000;
  }
`

const KListPanel: React.FC<KListPanelProps> = ({ numbers, onToggleParent }) => {
  return (
    <ListContainer>
      {numbers.map((i) => (
        <ListItem key={i.parent}>
          <PanelButton
            active={i.active}
            onClick={() => onToggleParent(i.parent)}
            title={i.parent}
          >
            {i.parent}
          </PanelButton>
        </ListItem>
      ))}
    </ListContainer>
  )
}

export default KListPanel
