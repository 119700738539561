import { useTranslation } from 'react-i18next'
import Modal, { Props } from 'react-modal'
import styled from 'styled-components'

const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  font-size: 20px;
  color: #ccc; /* Lighter gray color */
  transition: color 0.3s;

  &:hover {
    color: #999; /* Slightly darker on hover */
  }
`

const ModalContent = styled.div`
  max-height: 70vh;
  padding: 20px;
  background-color: #fff;
  overflow-y: auto;
  margin-top: 40px;
  scrollbar-width: thin;
  scrollbar-color: #ccc #f0f0f0;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
    border: 2px solid #f0f0f0;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
`

const ConfirmButton = styled.button`
  background-color: #f1b625;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  color: #333;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f7c248;
  }
`

const CancelButton = styled.button`
  background-color: #d3d3d3;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  color: #333;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ccc;
  }
`

export interface ModalComponentProps extends Props {
  onClickOk?: () => void
  onClickCancel?: () => void
  title?: string
  children?: React.ReactNode
}

const ModalComponent = (props: ModalComponentProps) => {
  const { t } = useTranslation()

  return (
    <Modal {...props}>
      <CloseIcon onClick={props?.onClickCancel}>✖</CloseIcon>
      <ModalContent>
        <div className="row">
          <div className="col-12">
            <h3 className="">{props.title}</h3>
          </div>
          {props.children}
          {props?.onClickOk && (
            <ButtonContainer>
              <ConfirmButton onClick={props?.onClickOk}>
                {t('message.confirm')}
              </ConfirmButton>
              <CancelButton onClick={props?.onClickCancel}>
                {t('message.cancel')}
              </CancelButton>
            </ButtonContainer>
          )}
        </div>
      </ModalContent>
    </Modal>
  )
}

Modal.setAppElement('#root')

export default ModalComponent
