import React from 'react'
import { Footer, Header, Loading } from '..'
export interface LayoutProps {
  hideMenu?: boolean
  children?: React.ReactNode
  loading?: boolean
  token?: string
}

const Layout = ({ children, hideMenu, loading }: LayoutProps) => {
  return (
    <React.Fragment>
      <div className="main-container">
        <Header hideMenu={hideMenu} />
        {loading && <Loading />}
        {!loading && children}
      </div>
      <Footer hideMenu={hideMenu} />
    </React.Fragment>
  )
}

export default Layout
