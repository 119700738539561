import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

export type BetRuleData = {
  bet_type: string
  bet_price: number
}

interface BetRulePanelProps {
  data: BetRuleData[]
}

const TableContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
`

const Header = styled.h3`
  background: linear-gradient(to bottom, #f1b625 0%, #f7c248 100%);
  color: #333;
  padding: 15px;
  text-align: center;
  font-size: 1.5rem;
`

const Table = styled.div`
  width: 100%;
  padding: 15px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
  }
`

const ColumnHeader = styled.div`
  font-size: 1.2rem;
  color: #999;
  margin-bottom: 10px;
`

const TAndCText = styled.p`
  color: #ff0000;
  text-align: center;
  margin-top: 20px;
`

const BetRulePanel = ({ data }: BetRulePanelProps) => {
  const { t } = useTranslation()

  return (
    <TableContainer>
      <Header>{t('betRule.payRate')}</Header>
      <Table>
        <Row>
          <ColumnHeader>{t('betRule.columns.betType')}</ColumnHeader>
          <ColumnHeader>{t('betRule.columns.betPrice')}</ColumnHeader>
        </Row>
        {data.map((row, index) => (
          <Row key={index}>
            <div>{t(`betDetailPanel.betLottoType.${row.bet_type}`)}</div>
            <div>{row.bet_price.toFixed(2)}</div>
          </Row>
        ))}
      </Table>
      <TAndCText>{t('betRule.t&c')}</TAndCText>
    </TableContainer>
  )
}

export default BetRulePanel
