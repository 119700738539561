import { useDatePickerInput } from 'components/DatePickerInput/useDatePickerInput'
// import { ResultTable } from 'components/ResultTable'
import ResultTable from 'components/@kapook/ResultTable/KResultTable'
import { SelectDropDownProps } from 'components/SelectDropDown'
import { GetResultProps } from 'pages/lotto/hook/useResulted'
import Resulted from 'pages/lotto/resulted'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'redux/reducers'
import { Game } from 'redux/reducers/gameReducer'
import { sagaActions } from 'redux/sagaActions'
import dayjs, { DATE_FORMAT_QUERY } from 'utils/date-time-utils'
import { isEncryptLotto, isYeekeeType } from 'utils/lotto-type-utils'
import { useIsSmallScreen } from 'hooks/useIsSamllScreen'

export const useResultPage = () => {
  const { i18n, t } = useTranslation()
  const dispatch = useDispatch()
  const [games, setGames] = useState<Game[]>([])
  const [selectedGame, setSelectedGame] = useState<number | undefined>(-1)
  const { results, loading } = useSelector((state: RootState) => ({
    results: state.games.results,
    loading: state.games.loading.results
  }))
  const optionsMenu: SelectDropDownProps['options'] = [...results].map((i) => {
    const name = i18n.language === 'th' ? i.name_th : i.name_en
    return { label: name, value: i.id }
  })
  optionsMenu.push({ value: -1, label: t('resultPage.all') })
  const handleSelect = (value: number) => {
    setSelectedGame(value)
  }
  const datePickerProps = useDatePickerInput()
  const navigation = useNavigate()
  const onBackAction = () => navigation(-1)
  const fetchResult = () => {
    const filterDate = dayjs(datePickerProps.selectedDate).format(
      DATE_FORMAT_QUERY
    )
    dispatch({
      type: sagaActions.FETCH_RESULTS,
      payload: { date: filterDate }
    })
  }

  useEffect(() => {
    fetchResult()
  }, [datePickerProps.selectedDate])
  useEffect(() => {
    if (selectedGame != -1) {
      const pGames = results.filter((i) => i.id == selectedGame)
      setGames(pGames)
    } else {
      setGames(results)
    }
  }, [selectedGame, loading])
  const isSmallScreen = useIsSmallScreen(425)
  const renderer: React.ReactNode[] = []
  games?.forEach((i) => {
    if (!isYeekeeType(i.type) && !isEncryptLotto(i.type)) {
      const props = GetResultProps(i18n, t, i.rounds?.[0], i, navigation)
      renderer.push(
        <Resulted key={i.id} {...props} noRound={i.rounds.length === 0} />
      )
    }
    if (isYeekeeType(i.type) || isEncryptLotto(i.type)) {
      const props = GetResultProps(
        i18n,
        t,
        undefined,
        i,
        navigation,
        false,
        isSmallScreen
      )
      renderer.push(
        <ResultTable
          key={i.id}
          {...props}
          data={i.rounds}
          noRound={i.rounds.length === 0}
        />
      )
    }
  })
  return {
    loading,
    onBackAction,
    ...datePickerProps,
    renderer,
    optionsMenu,
    handleSelect,
    selectedGame
  }
}
