import React, { useEffect, useState } from 'react'
import { Svg } from 'images'
import { BetDetailsPanelProps } from 'components/BetDetailsPanel'
import { LottoItem, LottoTypeGroup } from 'contexts/lotto-context'
import { NUMBER_FORMAT_WITHOUT_DECIMAL } from 'utils/number-utils'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

// Styled components for smaller screens without stacking
const BetListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 1024px) {
    gap: 8px;
    font-size: 0.85rem; /* Slightly smaller text on tablet */
  }
`

const BetItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    padding: 10px 12px;
  }
`

const LottoNumber = styled.span`
  font-size: 16px;
  font-weight: bold;

  @media (max-width: 1024px) {
    font-size: 14px; /* Reduce font size for tablet */
  }
`

const LottoQuantity = styled.input`
  width: 50px;
  text-align: center;
  padding: 6px;
  border-radius: 8px;
  border: 1px solid #ddd;

  @media (max-width: 1024px) {
    width: 40px;
  }
`

const LottoRate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 1024px) {
    font-size: 14px;
    gap: 8px;
  }
`

const WinRateText = styled.span`
  font-size: 14px;
  color: #333;

  @media (max-width: 1024px) {
    font-size: 12px;
  }
`

const WinRateDetail = styled.span`
  background-color: #ddd;
  border-radius: 50px;
  padding: 5px 15px;
  color: #333;
  font-size: 14px;

  @media (max-width: 1024px) {
    font-size: 12px;
    padding: 4px 12px;
  }
`

const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  img {
    width: 18px;
    height: 18px;

    @media (max-width: 1024px) {
      width: 16px;
      height: 16px;
    }
  }
`

type BetListItemProps = BetDetailsPanelProps & {
  disable?: boolean
  onDeleteItem: (key: number, type: string) => void
  onChangeSingleBetPrice: (type: string, idx: number, newNumber: number) => void
}

const KBetListItem = ({
  disable,
  listItem,
  onDeleteItem,
  onChangeSingleBetPrice
}: BetListItemProps) => {
  return (
    <BetListContainer>
      {listItem?.map((i: LottoTypeGroup) => (
        <React.Fragment key={i.type}>
          <h3 className="bet-list-item-title">{i.name}</h3>
          <div className="table-responsive table-bet-list">
            {i?.items?.map((item, idx) => (
              <BetItem
                key={`${idx}-${JSON.stringify(item)}`}
                idx={idx}
                type={i.type}
                disable={disable}
                {...item}
                onDeleteItem={onDeleteItem}
                onChangeSingleBetPrice={onChangeSingleBetPrice}
              />
            ))}
          </div>
        </React.Fragment>
      ))}
    </BetListContainer>
  )
}

type BetItemProps = LottoItem & {
  idx: number
  type: string
  disable?: boolean
  onDeleteItem: (key: number, type: string) => void
  onChangeSingleBetPrice: (type: string, idx: number, newNumber: number) => void
}

const BetItem = ({
  idx,
  type,
  number,
  price,
  rate,
  winPrice,
  onDeleteItem,
  onChangeSingleBetPrice,
  disable
}: BetItemProps) => {
  const [sprice, setBetPrice] = useState<number>(price)
  const { t } = useTranslation()

  return (
    <BetItemContainer>
      <LottoNumber>{number}</LottoNumber>
      <LottoQuantity
        type="number"
        value={isNaN(sprice) ? 1 : sprice}
        disabled={disable}
        onChange={(e) => {
          setBetPrice(e.target.valueAsNumber)
          onChangeSingleBetPrice(type, idx, e.target.valueAsNumber)
        }}
      />

      <LottoRate>
        <WinRateText>
          {t('betDetailPanel.win')}{' '}
          {numeral(winPrice).format(NUMBER_FORMAT_WITHOUT_DECIMAL)}
        </WinRateText>
        <WinRateDetail>{rate} ฿</WinRateDetail>
      </LottoRate>

      <DeleteButton onClick={() => onDeleteItem(idx, type)}>
        <img src={Svg.iconClose} alt="Delete" />
      </DeleteButton>
    </BetItemContainer>
  )
}

export default KBetListItem
