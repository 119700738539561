import React, { useEffect, useState, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { Kapook } from 'images'

export interface KDynamicMarqueeProps {
  speed: string
  children: React.ReactNode[] | React.ReactNode
}

// Define the keyframes for the marquee animation
const marqueeAnimation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`

// Styled component for the Marquee container
const MarqueeContainer = styled.div<KDynamicMarqueeProps>`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  animation: ${marqueeAnimation} ${({ speed }) => speed} infinite linear;
`

const AnnouncementWrapper = styled.div`
  background-color: #f8d78a;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  width: 1320px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 3rem !important;
`

const AnnouncementIcon = styled.div`
  position: absolute;
  flex: 0 0 auto;
  img {
    width: 70px;
    transform: rotate(-15deg);
  }
`

const KDynamicMarquee: React.FC<KDynamicMarqueeProps> = ({
  speed,
  children
}) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0)
  const childArray = Array.isArray(children) ? children : [children]
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth
      const textWidth = containerRef.current.scrollWidth
      const animationDuration = (textWidth / containerWidth) * parseFloat(speed)
      const interval = setInterval(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % childArray.length)
      }, animationDuration * 1000)

      return () => clearInterval(interval)
    }
  }, [speed, childArray.length])

  return (
    <div className="container">
      <AnnouncementWrapper>
        <AnnouncementIcon>
          <img src={Kapook.kIconAnnounce} alt="ประกาศ" />
        </AnnouncementIcon>
        <div
          style={{
            backgroundColor: '#fff',
            padding: '10px',
            borderRadius: '10px',
            width: '100%',
            overflow: 'hidden',
            marginLeft: '4.5rem'
          }}
        >
          <MarqueeContainer ref={containerRef} speed={speed}>
            {childArray[currentTextIndex]}
          </MarqueeContainer>
        </div>
      </AnnouncementWrapper>
    </div>
  )
}

export default KDynamicMarquee
