import { all } from 'redux-saga/effects'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { watchFetchTodos } from './fetachTodoSaga'
import { watchFetchListGame } from './fetchGameListSaga'
import { watchUser } from './fetchUserSage'
import { initialize } from './initializeSaga'
import { routerHandlerSaga } from './routerHandlerSaga'
import { FixTypeLater } from 'react-redux'
import { watchYeekeeGame } from './yeekeeSaga'
import { watchGlobalError } from './errorHandlerSaga'
import { watchFetchLimit } from './limitHandlerSaga'

export const callAPI = async ({
  url,
  method,
  data,
  headers = {}
}: AxiosRequestConfig): Promise<AxiosResponse> => {
  const maxRetries = 3
  let attempt = 0

  // Set default headers including tokens
  headers = {
    ...headers,
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    AccessToken: localStorage.getItem('access_token')
  }

  while (attempt < maxRetries) {
    try {
      const response = await axios({
        url,
        method,
        data,
        headers,
        timeout: 5000 // Set timeout in ms (adjust as needed)
      })
      return response // Return response if successful
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.code === 'ECONNABORTED') {
        attempt++
        console.warn(`Request timeout. Retry attempt ${attempt}/${maxRetries}`)

        if (attempt >= maxRetries) {
          window.location.href = '/session_error'
          throw new Error('Redirecting to session error due to timeout.')
        }
      } else {
        console.error('API call failed with error:', error)
        return Promise.reject(error)
      }
    }
  }

  throw new Error('Unexpected error in API call.')
}

export const callPublicAPI = async ({
  url,
  method,
  data // headers
}: AxiosRequestConfig): Promise<FixTypeLater> => {
  return await axios({
    url,
    method,
    data
    // headers
  })
}

export interface ResponseStructure {
  status: string
  message: string
}

export interface ResponsePaginationStructure {
  limit: number
  page: number
  count: number
}

export interface PaginationPayloadPaginationStructure {
  page: number
  pageSize: number
}
function* rootSaga() {
  yield all([
    watchGlobalError(),
    watchFetchTodos(),
    watchFetchListGame(),
    watchYeekeeGame(),
    watchFetchLimit(),
    watchUser(),
    initialize(),
    routerHandlerSaga()
  ])
}
export default rootSaga
