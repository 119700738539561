import ListManualBet from 'components/@kapook/ListManualBet/KListManualBet'
import NumberInput from 'components/@kapook/NumberInput/KNumberInput'
// import { ListManualBet, NumberInput } from 'components'
import { useManualBet } from '../hook/useManualBet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
const PStyled = styled.p`
  text-align: center;

  @media (max-width: 990px) {
    margin-top: 20px;
  }
`

const ManualBet = () => {
  const { t } = useTranslation()
  const {
    renderItem,
    selected,
    onSelectNewMenu,
    maxDigit,
    addDigit,
    deleteNumber,
    currentNumber
  } = useManualBet()
  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        <ListManualBet
          items={renderItem}
          selected={selected}
          onChangeBet={onSelectNewMenu}
        />
      </div>
      <div className="col-12 col-lg-6">
        <PStyled>{t('lottoPage.inputNumber')}</PStyled>
        <NumberInput
          maxDigit={maxDigit}
          onAddNumber={addDigit}
          onDeleteNumber={deleteNumber}
          currentNumber={currentNumber}
        />
      </div>
    </div>
  )
}

export default ManualBet
