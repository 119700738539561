import NavbarBetFromPanel from 'components/@kapook/Navbar/KNavbarBetFromPanel'
import { navItem } from 'components/Navbar'
import { useNavBar } from 'components/Navbar/useNavbar'
import { LottoType } from 'types/navbar'
import ThreeDigitPanel from 'pages/lotto/bet-lotto/bet-from-panel/three-digit-panel'
import TwoDigitPanel from 'pages/lotto/bet-lotto/bet-from-panel/two-digit-panel'
import RunningDigitPanel from 'pages/lotto/bet-lotto/bet-from-panel/running-digit-panel'
import { useTranslation } from 'react-i18next'

export const KBetFromPanel = () => {
  const { t } = useTranslation()
  const navbar_tab_item: navItem[] = [
    {
      name: t('betDetailPanel.betCategory.bet_three'),
      key: 'three_digit'
    },
    {
      name: t('betDetailPanel.betCategory.bet_two'),
      key: 'two_digit'
    },
    {
      name: t('betDetailPanel.betCategory.bet_one'),
      key: 'running_digit'
    }
  ]
  const navTabAction = useNavBar<LottoType>('three_digit')
  const renderPanelContent = () => {
    switch (navTabAction.selected) {
      case 'three_digit':
        return <ThreeDigitPanel />
      case 'two_digit':
        return <TwoDigitPanel />
      case 'running_digit':
        return <RunningDigitPanel />
    }
  }
  return (
    <div className="select-bet-lotto-container">
      <NavbarBetFromPanel
        items={navbar_tab_item}
        onChangeItem={navTabAction.setSelectedItem}
        selected={navTabAction.selected}
      />
      {renderPanelContent()}
    </div>
  )
}
export default KBetFromPanel
