import React from 'react'
import styled from 'styled-components'

export type KTwoNumberFunctionPanelProps = {
  title: string
  type: string
  value?: string[]
  onSelectNumber?: (number: string, type: string) => void
}

const FunctionPanelContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
`

const Title = styled.h3`
  font-size: 14px;
  text-align: center;
  margin-bottom: 8px;
`

const NumberList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0 auto;
  max-width: 100%;
  flex-wrap: wrap;
`

const NumberItem = styled.li`
  flex-basis: calc(10% - 5px);
  max-width: calc(10% - 5px);
  flex-grow: 1;
  margin-bottom: 5px;
`

const StyledPanelButton = styled.button<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#f1b625' : 'transparent')};
  color: ${(props) => (props.active ? '#000' : '#333')};
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f7c248;
  }

  @media (max-width: 1400px) {
    font-size: 10px;
  }

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`

const KTwoNumberFunctionPanel: React.FC<KTwoNumberFunctionPanelProps> = ({
  title,
  value,
  type,
  onSelectNumber
}) => {
  return (
    <FunctionPanelContainer>
      <Title>{title}</Title>
      <NumberList>
        {['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].map((i) => (
          <NumberItem key={`${type}${i}`}>
            <StyledPanelButton
              active={value?.includes(i) ?? false}
              onClick={() => onSelectNumber?.(i, type)}
            >
              {i}
            </StyledPanelButton>
          </NumberItem>
        ))}
      </NumberList>
    </FunctionPanelContainer>
  )
}

export default KTwoNumberFunctionPanel
