import React from 'react'
import styled from 'styled-components'
import { Svg } from 'images'

export interface KNumberInputProps {
  maxDigit: number
  currentNumber: number[]
  onAddNumber?: (digit: number) => void
  onDeleteNumber?: () => void
  loading?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
`

const InputRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 12px;

  @media (max-width: 768px) {
    gap: 8px;
  }
`

const NumberBox = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Lighter background */
  border-radius: 8px;
  font-size: 22px; /* Slightly smaller font size */
  color: #333; /* Darker text color */
  margin: 5px;

  @media (max-width: 768px) {
    width: 45px;
    height: 45px;
    font-size: 20px;
  }

  @media (max-width: 480px) {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
`

const KeypadContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  width: 100%;
  max-width: 300px;

  @media (max-width: 768px) {
    gap: 8px;
    max-width: 240px;
  }

  @media (max-width: 480px) {
    gap: 6px;
    max-width: 200px;
  }
`

const LastRowContainer = styled(KeypadContainer)`
  margin-top: 12px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;

  @media (max-width: 768px) {
    gap: 12px;
  }
`

const KeyButton = styled.button<{ disabled?: boolean }>`
  width: 100%;
  height: 60px;
  background-color: ${({ disabled }) =>
    disabled ? '#f0f0f0' : '#f0f0f0'}; /* Lighter button background */
  color: ${({ disabled }) => (disabled ? '#bbb' : '#000')};
  border: none;
  border-radius: 8px;
  font-size: 22px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? '#f0f0f0' : '#e0e0e0'}; /* Lighter hover state */
  }

  @media (max-width: 768px) {
    height: 55px;
    font-size: 20px;
  }

  @media (max-width: 480px) {
    height: 50px;
    font-size: 18px;
  }

  img {
    width: 20px;
    height: auto;
  }
`

const KNumberInput: React.FC<KNumberInputProps> = ({
  maxDigit,
  currentNumber,
  onAddNumber,
  onDeleteNumber,
  loading
}) => {
  return (
    <Container>
      <InputRow>
        {Array.from({ length: maxDigit }, (_, k) => (
          <NumberBox key={k}>{currentNumber?.[k] ?? ''}</NumberBox>
        ))}
      </InputRow>
      <KeypadContainer>
        {Array.from({ length: 9 }, (_, i) => (
          <KeyButton
            key={i + 1}
            disabled={loading}
            onClick={() => !loading && onAddNumber?.(i + 1)}
          >
            {i + 1}
          </KeyButton>
        ))}
      </KeypadContainer>
      <LastRowContainer>
        <KeyButton disabled={loading}>
          <img src={Svg.iconEnter || 'defaultEnterIcon.svg'} alt="Enter" />
        </KeyButton>
        <KeyButton
          disabled={loading}
          onClick={() => !loading && onAddNumber?.(0)}
        >
          0
        </KeyButton>
        <KeyButton
          disabled={loading}
          onClick={() => !loading && onDeleteNumber?.()}
        >
          <img src={Svg.iconBack || 'defaultBackIcon.svg'} alt="Back" />
        </KeyButton>
      </LastRowContainer>
    </Container>
  )
}

export default KNumberInput
