import { useLottoContext } from 'contexts'
import { set } from 'lodash'
import { ChangeEvent, useEffect, useState } from 'react'
import {
  ThreeNumberParent,
  generatePermutationsWithString,
  generateThreeNumber
} from 'utils/number-utils'
export const useThreeNumber = () => {
  const {
    addLottoListAndHandleResponse,
    lottoGroupItem,
    deleteLottoListByNumber,
    lottoConfig,
    getLimitTypeUI
  } = useLottoContext()
  const [searchDigit, SetSearchDigit] = useState<string>('')
  const [swapNumber, setSwapNumber] = useState<boolean>(false)
  const [menuSelected, setMenuSelect] = useState<string[]>([])
  const [parentSelected, SetParentSelected] = useState<string>('000')
  const [numbers, setNumber] = useState<ThreeNumberParent[]>(
    generateThreeNumber()
  )
  const THREE_NUMBER = [
    'bet_three_top',
    'bet_three_tod',
    'bet_three_front',
    'bet_three_under',
    'bet_three_front_tod'
  ]
  const { betThree: menuToReward } = lottoConfig
  useEffect(() => {
    const pSelected = lottoGroupItem
      .filter((lotto) => THREE_NUMBER.includes(lotto.type))
      ?.map((v) => v?.items?.map((i) => i.number))
      .reduce((acc, cur) => acc.concat(cur), [])
    const selectedNum = Array.from(new Set(pSelected))
    const updateNumber = numbers.map((i) => {
      return {
        ...i,
        children: i.children.map((c) => {
          return {
            ...c,
            active: selectedNum.includes(c.number)
          }
        })
      }
    })
    setNumber(updateNumber)
  }, [JSON.stringify(lottoGroupItem)])
  useEffect(() => {
    if (!!searchDigit) {
      const pSelected = lottoGroupItem
        .filter((lotto) => THREE_NUMBER.includes(lotto.type))
        ?.map((v) => v?.items?.map((i) => i.number))
        .reduce((acc, cur) => acc.concat(cur), [])
      const selectedNum = Array.from(new Set(pSelected))
      let newNumbers = generateThreeNumber().map((i) => {
        return {
          ...i,
          active: i.parent === parentSelected,
          children: i.children.map((c) => {
            return {
              ...c,
              active: selectedNum.includes(c.number)
            }
          })
        }
      })
      newNumbers = newNumbers.map((i) => ({
        ...i,
        children: i.children.filter((c) => c.number.includes(searchDigit))
      }))
      setNumber(newNumbers)
    } else {
      setNumber(generateThreeNumber())
    }
  }, [searchDigit])
  const onToggleSwapNumber = () => {
    setSwapNumber(!swapNumber)
  }
  const onSearchDigit = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (value.length > 0) {
      const formattedValue = value.replace(/\D/g, '').slice(0, 3)
      SetSearchDigit(formattedValue)
    } else {
      SetSearchDigit('')
    }
  }
  const onToggleParent = (number: string) => {
    const newNumbers = numbers.map((i) => {
      const active = number === i.parent ? true : false
      i.active = active
      return i
    })
    SetParentSelected(number)
    setNumber(newNumbers)
  }
  const onToggleMenu = (menu: string) => {
    const newMenu = new Set([...menuSelected])
    if (newMenu.has(menu)) {
      newMenu.delete(menu)
    } else {
      newMenu.add(menu)
    }
    setMenuSelect(Array.from(newMenu))
  }
  const onClickNumber = async (number: string) => {
    const selectedList = lottoGroupItem
      .filter(
        (lotto) =>
          THREE_NUMBER.includes(lotto.type) &&
          lotto.items.some((i) => i.number === number)
      )
      .flatMap((v) => v.items.map((i) => i.number))

    const createParams = (menuSelected: string[]) => {
      return menuSelected?.reduce((acc: Record<string, number>, menuOption) => {
        acc[menuOption] = menuToReward[menuOption]
        return acc
      }, {})
    }

    if (selectedList.length > 0) {
      menuSelected?.forEach((menuOption) => {
        deleteLottoListByNumber(number, menuOption)
      })
    } else {
      const params = createParams(menuSelected)
      if (swapNumber) {
        const results = generatePermutationsWithString(number)
        results.forEach((result) => {
          addLottoListAndHandleResponse(result, params)
        })
      } else {
        addLottoListAndHandleResponse(number, params)
      }
    }
  }

  return {
    numbers,
    onToggleParent,
    menuSelected,
    onToggleMenu,
    onClickNumber,
    swapNumber,
    onToggleSwapNumber,
    searchDigit,
    onSearchDigit,
    betThree: lottoConfig.betThree,
    getLimitTypeUI
  }
}
