import styled from 'styled-components'
import { TWO_NUMBER_LIST } from 'components/ListManualBet/useManualBetItem'

export type BetItemData = {
  name: string
  key: string
  digit: number
  parent?: string
  onClick?: () => void
}

export interface BetItemProps {
  name: string
  accessor: string
  active: boolean
  onChangeBet: () => void
}

export interface ListManualBetProps {
  selected?: string[]
  onChangeBet: (key: string) => void
  items: BetItemData[]
}

const ListItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  margin-bottom: 10px;
  padding: 8px;
  text-align: center;
  border-radius: 12px;
  border: 1px solid #ccc;
  background-color: ${({ active }) => (active ? '#ffbf00' : '#f1f1f1')};
  color: ${({ active }) => (active ? '#fff' : '#000')};
  font-size: 14px;
  width: 48%;
  margin-right: 2%;

  &:nth-child(2n) {
    margin-right: 0;
  }

  &:hover {
    background-color: #ffbf00;
    color: #fff;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 6px;
  }
`

const ManualBetItem = ({
  name,
  accessor,
  active,
  onChangeBet
}: BetItemProps) => (
  <ListItem active={active} onClick={onChangeBet}>
    {name}
  </ListItem>
)

const ListManualBet = ({
  selected = [],
  onChangeBet,
  items
}: ListManualBetProps) => {
  const isRenderSpecialTwoDigit = (key: string) => {
    const rules =
      ((TWO_NUMBER_LIST.includes(key) && selected?.includes('bet_two_top')) ||
        selected?.includes('bet_two_under') ||
        selected?.includes('tos_two')) &&
      !selected.includes('bet_three_top') &&
      !selected.includes('bet_three_tod') &&
      !selected.includes('swap_three')
    return rules
  }

  const renderManualBetItem = (item: BetItemData) => {
    if (
      !TWO_NUMBER_LIST.includes(item.key) ||
      isRenderSpecialTwoDigit(item.key)
    ) {
      return (
        <ManualBetItem
          key={item.key}
          active={selected.includes(item.key)}
          name={item.name}
          accessor={item.key}
          onChangeBet={() => item.onClick?.() ?? onChangeBet(item.key)}
        />
      )
    }
  }

  return <div className="row">{items.map(renderManualBetItem)}</div>
}

export default ListManualBet
